import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { GlobalService } from 'src/app/global.service';

@Component({
    selector: 'app-password-change-option',
    templateUrl: './password-change-option.component.html',
    styleUrls: ['./password-change-option.component.scss']
})
export class PasswordChangeOptionComponent implements OnInit {
    user_name: any;
    user_type_id: any;
    mobile: any;
    user_id: any;
    entered_mobile_number = null;
    enable_otp = false;
    entered_otp = null;
    enable_password_change_option = false;
    new_pass_type: any = 'password';
    confirm_pass_type: any = 'password';
    confirm_pass: any;
    new_pass: any;
    alert: any = '';

    constructor(private httpService: HttpService, private router: Router, private dialogRef: MatDialogRef<PasswordChangeOptionComponent>,
        private global: GlobalService) {
        this.user_name = JSON.parse(localStorage.getItem('first_name'));
        this.user_type_id = JSON.parse(localStorage.getItem('user_type_id'));
        this.mobile = JSON.parse(localStorage.getItem('mobile'));
        this.user_id = JSON.parse(localStorage.getItem('user_id'));
    }

    closeModel() {
        this.dialogRef.close();
        if (this.user_type_id === 3) {
            this.router.navigateByUrl('/icustomer/order/form');
        } else {
            this.router.navigateByUrl('/agent/order/form');
        }
    }


    proceedToChangeCalled() {
        let data_dict = {
            'mobile_number': this.mobile,
            'user_id': this.user_id,
            'for': 'Password reset is '

        };
        this.httpService.sendOtpToMobileNumber(data_dict).subscribe((data) => {
            // this.router.navigateByUrl('/icustomer/order/form');
            this.enable_otp = true;
        }, (error) => {
            console.error(error);
        });
    }

    confirmWithOtpClicked() {
        let data_dict = {
            'mobile_number': this.mobile,
            'user_id': this.user_id,
            'otp': this.entered_otp,
            'user_type_id': this.user_type_id,
        };
        this.httpService.confirmOtpCalledForPasswordChange(data_dict).subscribe((data) => {
            this.enable_password_change_option = true;
            this.entered_otp = null;
        }, (error) => {
            alert('Incorrect OTP');
            console.error(error);
        });
    }

    updatePasswordCalled() {
        let data_dict = {
            'user_id': this.user_id,
            'password': this.new_pass,
            'user_type_id': this.user_type_id,
            'mobile': this.mobile
        };
        this.httpService.updatePasswordForUser(data_dict).subscribe((data) => {
            this.dialogRef.close();
            this.global.showSnakeBar('Password Changed Successfully!');
            if (this.user_type_id === 3) {
                this.router.navigateByUrl('/icustomer/order/form');
            } else {
                this.router.navigateByUrl('/agent/order/form');
            }
        }, (error) => {
            alert('Incorrect OTP');
            console.error(error);
        });
    }

    confirmPassword() {
        if (this.confirm_pass !== this.new_pass) {
            this.alert = '*passwords does not match';
        } else {
            this.alert = '';
        }
        if (this.confirm_pass === '') {
            this.alert = '';
        }
    }

    showNewPassword() {
        if (this.new_pass_type === 'password') {
            this.new_pass_type = 'text';
        } else {
            this.new_pass_type = 'password';
        }
    }

    showConfirmPassword() {
        if (this.confirm_pass_type === 'password') {
            this.confirm_pass_type = 'text';
        } else {
            this.confirm_pass_type = 'password';
        }
    }

    ngOnInit() {
    }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerRegisterValidator } from './customer-register-validators';
import { HttpService } from 'src/app/http.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { IndivioulBoothDetailsComponent } from '../indivioul-booth-details/indivioul-booth-details.component';
import { LoginComponent } from '../../login/login.component';
// import { MatStepper } from '@angular/material/stepper';


@Component({
    selector: 'app-customer-register',
    templateUrl: './customer-register.component.html',
    styleUrls: ['./customer-register.component.scss']
})
export class CustomerRegisterComponent implements OnInit {
    isLinear = false;
    register_form: FormGroup;
    new_pass_type: any = 'password';
    confirm_pass_type: any = 'password';
    alert: any;
    states: any;
    districts: any;
    taluks: any;
    genders: any = [];
    is_otp_visible = false;
    is_customer_exists = false;
    is_phone_editable = false;
    otp_attempt_count: any = 0;
    picture: any = null;
    show_main_form: boolean = false;
    form_save = false;
    user_profile_id: any = null;
    booth_list: any;
    booth_lat_lng_data: any = [];
    lat: number = 11.010778;
    lng: number = 76.954279;
    zoom: number = 12;
    timer_start_from: number;
    business_list: any;
    filtered_business_list: any;
    search_term: any = null;
    selected_business_id: any = null;
    customer_code: any = null;

    constructor(private formBuilder: FormBuilder, private httpService: HttpService, private dialog: MatDialog) {
        this.register_form = this.formBuilder.group({
            first_name: [null, Validators.compose([Validators.required])],
            last_name: [null, Validators.compose([Validators.required])],
            email: [null, Validators.compose([CustomerRegisterValidator.checkEmail])],
            gender: [null, Validators.compose([Validators.required])],
            street: [null],
            taluk: [null, Validators.compose([Validators.required])],
            district: [1, Validators.compose([Validators.required])],
            state: [1, Validators.compose([Validators.required])],
            pincode: [null, Validators.compose([CustomerRegisterValidator.checkPincode, Validators.required])],
            phone: [null, Validators.compose([CustomerRegisterValidator.checkPhoneNumber, Validators.required])],
            otp: [null, Validators.compose([Validators.required])],
            alternate_mobile: [null, Validators.compose([CustomerRegisterValidator.checkPhoneNumber])],
            aadhar_card_number: [null, Validators.compose([CustomerRegisterValidator.checkAadharNumber, Validators.required])],
            aadhar_image: [null],
            password: [null, Validators.compose([Validators.required])],
            confirm_password: [null, Validators.compose([Validators.required, CustomerRegisterValidator.checkConfirmPassword])],
            latitude: [null, Validators.compose([])],
            longitude: [null, Validators.compose([])],
        });
        this.httpService.serve_general_data_for_registration().subscribe((data) => {
            this.districts = data['location_details']['districts'];
            this.taluks = data['location_details']['taluks'];
            this.genders = data['genders'];
        }, (error) => {
            console.error(error);
        });
        this.httpService.getAllbusinessForBothChange().subscribe((data: []) => {
            this.business_list = data;
        }, (error) => {
            console.error(error);
        });
    }

    searchBusinessCalled(search_term) {
        this.selected_business_id = null;
        if (this.search_term == null || this.search_term === '') {
            this.filtered_business_list = [];
        } else {
            this.filtered_business_list = this.business_list.filter((element) => {
                return element.name.toLowerCase().indexOf(search_term.toLowerCase()) > -1 || element.code.toLowerCase().indexOf(search_term.toLowerCase()) > -1;
            });
        }
    }

    boothSelectedFromList(business_id) {
        this.selected_business_id = business_id;
    }

    toBase64AadharDocument(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = (e) => {
            console.log(reader.result);
            this.register_form.get('aadhar_image').setValue(reader.result);
        };
        console.log(reader.readAsDataURL(file));
    }

    async checkCustomerExists() {
        let data_dict = {
            'phone': this.register_form.value.phone
        };
        this.httpService.checkCustomerExists(data_dict).subscribe((data) => {
            if (data['status']) {
                alert('User already esists');
                return;
            } else {
                this.is_customer_exists = data['status'];
                this.is_otp_visible = true;
                this.is_phone_editable = true;
                this.timer_start_from = 40;
                setInterval(() => {
                    if (this.timer_start_from > 0) {
                        this.timer_start_from -= 1;
                    }
                }, 1000);
            }
        }, (error) => {
            console.error(error);
        });
    }

    checkOtpValid() {
        let data_dict = {
            'customer_exists': this.is_customer_exists,
            'otp': this.register_form.value.otp,
            'phone': this.register_form.value.phone
        };
        if (this.otp_attempt_count !== 3) {
            this.httpService.otpValidationForTempRegistration(data_dict).subscribe((data) => {
                if (this.is_customer_exists) {
                    alert('User already esists');
                    this.register_form.reset();
                    this.otp_attempt_count = 0;
                    this.is_phone_editable = false;
                    this.is_otp_visible = false;
                } else {
                    this.show_main_form = true;
                }
            }, (error) => {
                console.error(error);
                this.otp_attempt_count += 1;
                let error_messge = error.error;
                this.register_form.get('otp').setValue(null);
                alert('Incorrect OTP');
            });
        } else {
            alert('You have crossed 3 attempts');
            this.register_form.reset();
            this.otp_attempt_count = 0;
            this.is_phone_editable = false;
            this.is_otp_visible = false;
        }
    }

    async registerClicked() {
        let data_dict = {
            'form_values': this.register_form.value
        };
        this.httpService.registerCustomer(data_dict).subscribe((data) => {
            this.form_save = true;
            let element: HTMLElement = document.getElementById('next_stepper') as HTMLElement;
            element.click();
            this.user_profile_id = data['user_profile_id']
            this.serveBoothList();
            this.serveBoothLatLng();
        }, (error) => {
            console.error(error);
        });
    }

    serveBoothList() {
        let data_dict = {
            'pincode': this.register_form.value.pincode
            // 'pincode': '641048'
        };
        this.httpService.serveBoothListUnderPincode(data_dict).subscribe((data) => {
            this.booth_list = data;
        }, (error) => {
            console.error(error);
        });
    }

    async serveBoothLatLng() {
        this.httpService.serveBoothLatLng().subscribe((data) => {
            this.booth_lat_lng_data = data;
        }, (error) => {
            console.error(error);
        });
    }

    async confirmCicked(business_id) {

        let data_dict = {
            'user_profile_id': this.user_profile_id,
            'page_action': 'register',
            'business_id': business_id,
            'from': 'website'
        };
        if (confirm('Are you sure to confirm ?')) {
            this.httpService.linkBoothWithCustomer(data_dict).subscribe((data) => {
                this.customer_code = data['customer_code'];
                let element: HTMLElement = document.getElementById('next_stepper') as HTMLElement;
                element.click();
            }, (error) => {
                console.error(error);
            });
        }
    }
    onBoothSelect(business_data) {
        const dialogRef = this.dialog.open(IndivioulBoothDetailsComponent, {
            panelClass: 'modal-class',
            data: {
                'business_id': business_data['business_id'],
                'user_profile_id': this.user_profile_id
            }
        });
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                let element: HTMLElement = document.getElementById('next_stepper') as HTMLElement;
                element.click();
            }
        });
    }

    loginPageClicked() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'modal-class';
        this.dialog.open(LoginComponent, dialogConfig);
    }


    ngOnInit() {
    }

}

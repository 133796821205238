import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { Router } from '@angular/router';
import { PasswordChangeOptionComponent } from 'src/app/component/pages/password-change-option/password-change-option.component';
import { MatDialog } from '@angular/material';
import { GlobalService } from 'src/app/global.service';

@Component({
    selector: 'app-mobile-number-verification',
    templateUrl: './mobile-number-verification.component.html',
    styleUrls: ['./mobile-number-verification.component.scss']
})
export class MobileNumberVerificationComponent implements OnInit {
    user_name: any;
    user_type_id: any;
    mobile: any;
    user_id: any;
    entered_mobile_number = null;
    enable_otp = false;
    entered_otp = null;
    enable_otp_for_old_number = false;

    constructor(private httpService: HttpService, private router: Router, public dialog: MatDialog, private global: GlobalService) {
        this.user_name = JSON.parse(localStorage.getItem('first_name'));
        this.user_type_id = JSON.parse(localStorage.getItem('user_type_id'));
        this.mobile = JSON.parse(localStorage.getItem('mobile'));
        this.user_id = JSON.parse(localStorage.getItem('user_id'));
    }

    confirmButtonClicked() {
        let data_dict = {
            'user_type_id': this.user_type_id,
            'user_id': this.user_id
        };
        this.httpService.confirmMobileVerfication(data_dict).subscribe((data) => {
            // if (this.user_type_id === 3) {
            //     this.router.navigateByUrl('/icustomer/order/form');
            // } else {
            //     this.router.navigateByUrl('/agent/order/form');
            // }
            this.openPasswordChangeComponentCalled();
        }, (error) => {
            console.error(error);
        });
    }

    updateMobileCalledFoOldNumber() {
        let data_dict = {
            'mobile_number': this.mobile,
            'user_id': this.user_id,
            'for': 'Mobile verfication is '
        };

        this.httpService.sendOtpToMobileNumber(data_dict).subscribe((data) => {
            // this.router.navigateByUrl('/icustomer/order/form');
            this.enable_otp_for_old_number = true;
            this.entered_mobile_number = this.mobile;
        }, (error) => {
            console.error(error);
        });
    }

    updateMobileCalled() {
        let data_dict = {
            'mobile_number': this.entered_mobile_number,
            'user_id': this.user_id,
            'for': 'Mobile verfication is '
        };

        this.httpService.sendOtpToMobileNumber(data_dict).subscribe((data) => {
            // this.router.navigateByUrl('/icustomer/order/form');
            this.enable_otp = true;
        }, (error) => {
            console.error(error);
        });
    }

    confirmWithOtpClicked() {
        let data_dict = {
            'mobile_number': this.entered_mobile_number,
            'user_id': this.user_id,
            'otp': this.entered_otp,
            'user_type_id': this.user_type_id,
        };
        this.httpService.confirmOtpCalled(data_dict).subscribe((data) => {
            // if (this.user_type_id === 3) {
            //     this.router.navigateByUrl('/icustomer/order/form');
            // } else {
            //     this.router.navigateByUrl('/agent/order/form');
            // }
            this.global.showSnakeBar('Mobile Number Updated');
            localStorage.setItem('mobile', JSON.stringify(this.entered_mobile_number));
            this.openPasswordChangeComponentCalled();
            // this.enable_otp = true;
        }, (error) => {
            alert('Incorrect OTP');
            console.error(error);
        });
    }

    openPasswordChangeComponentCalled() {
        const dialogRef = this.dialog.open(PasswordChangeOptionComponent, {
            height: '500px',
            width: '600px',
            data: {},
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            const res = result;
        });
    }
    ngOnInit() {
    }

}

import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-indivioul-booth-selection',
    templateUrl: './indivioul-booth-selection.component.html',
    styleUrls: ['./indivioul-booth-selection.component.scss']
})
export class IndivioulBoothSelectionComponent implements OnInit {
    user_profile_id: any;
    booth_details: any;
    page_action: any;
    selected_date: any;

    constructor(private dialogRef: MatDialogRef<IndivioulBoothSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private httpService: HttpService) {
        this.user_profile_id = data['user_profile_id'];
        this.selected_date = data['selected_date'];
        this.page_action = data['page_action'];
        let data_dict = {
            'business_id': data['business_id']
        };
        this.httpService.serveSelecetedBoothDetails(data_dict).subscribe((data) => {
            this.booth_details = data;
        }, (error) => {
            console.error(error);
        });
    }

    async confirmCicked(business_id) {

        let data_dict = {
            'user_profile_id': this.user_profile_id,
            'page_action': this.page_action,
            'business_id': business_id,
            'from': 'portal'
        };
        if (this.page_action === 'after_order') {
            data_dict['selected_date'] = this.selected_date;
        }
        if (confirm('Are ypu sure to confirm ?')) {
            this.httpService.linkBoothWithCustomer(data_dict).subscribe((data) => {
                this.dialogRef.close(true);
            }, (error) => {
                console.error(error);
            });
        }
    }

    closeClicked() {
        this.dialogRef.close(false);
    }

    ngOnInit() {
    }

}

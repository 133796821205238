import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-payment-check-page',
    templateUrl: './payment-check-page.component.html',
    styleUrls: ['./payment-check-page.component.scss']
})
export class PaymentCheckPageComponent implements OnInit {
    encrypted_string: any;

    constructor(private httpService: HttpService, private httpClient: HttpClient) { }

    async getStringCalled() {
        this.httpService.serveEncryptionKey().subscribe((data) => {
            this.encrypted_string = data['encrypted_string'];
        }, (error) => {
            console.error(error);
        });

    }
    submitFormCalled() {
        // var myForm = <HTMLFormElement>document.getElementById('Formdata');
        // myForm.submit();
        window.location.href = "https://easypay.axisbank.co.in/index.php/api/payment?i=" + String(this.encrypted_string);
        // this.httpClient.post('')
    }

    ngOnInit() {
    }

}

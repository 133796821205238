import { Component, OnInit } from '@angular/core';
import { DataTransferService } from 'src/app/data-transfer.service';
import { HttpService } from 'src/app/http.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerRegisterValidator } from '../customer-register/customer-register-validators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-agent-request-form',
    templateUrl: './agent-request-form.component.html',
    styleUrls: ['./agent-request-form.component.scss']
})
export class AgentRequestFormComponent implements OnInit {
    selected_booth_type: any;
    document_type_list: any;
    register_form: FormGroup;

    constructor(private dataTransfer: DataTransferService, private httpService: HttpService, private formBuilder: FormBuilder,
        private router: Router) {
        this.selected_booth_type = this.dataTransfer.selected_option;
        this.httpService.serveBusinessTypes().subscribe((data) => {
            this.document_type_list = data['document_type'];
        }, (error) => {
            console.error(error);
        });
        this.register_form = this.formBuilder.group({
            first_name: [null, Validators.compose([Validators.required])],
            last_name: [null],
            address: [null, Validators.compose([Validators.required])],
            pincode: [null, Validators.compose([Validators.required, CustomerRegisterValidator.checkPincode])],
            phone_number: [null, Validators.compose([Validators.required, CustomerRegisterValidator.checkPhoneNumber])],
            booth_type: [this.selected_booth_type['id'], Validators.compose([Validators.required])],
        });

    }

    toBase64AadharDocument(event, index) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = (e) => {
            console.log(reader.result);
            this.document_type_list[index]['document_value'] = reader.result;
        };
        console.log(reader.readAsDataURL(file));
    }

    registerClicked() {
        let data_dict = {
            'form_values': this.register_form.value,
            'document_value': this.document_type_list
        };
        this.httpService.saveAgentRequestForm(data_dict).subscribe((data) => {
            this.register_form.reset();
            this.document_type_list.forEach((element, index) => {
                this.document_type_list[index]['document_value'] = null;
            });
            alert('Your request submitted')
            this.router.navigateByUrl('');
        }, (error) => {
            console.error(error);
        });
    }

    ngOnInit() {
    }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-order-history',
  templateUrl: './agent-order-history.component.html',
  styleUrls: ['./agent-order-history.component.scss']
})
export class AgentOrderHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

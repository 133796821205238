import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalService } from "./global.service";
import { AuthenticationService } from "./components/authentication/authentication.service";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { OrderCategorySelectionComponent } from "./by_products/order-category-selection/order-category-selection.component";
import { TimeMismatchAlertComponent } from "./app/component/time-mismatch-alert/time-mismatch-alert.component";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class HttpService {
    headers: HttpHeaders;

    constructor(
        private router: Router,
        private global: GlobalService,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private dialog: MatDialog
    ) {
        this.headers = new HttpHeaders();
        this.appendTokenToHeaderByLocalStorage();
        this.authenticationService.login_event.subscribe((data) => {
            this.setTokentoHeaders(data);
        });
    }

    setTokentoHeaders(token: any) {
        localStorage.removeItem("auth-token");
        localStorage.setItem("auth-token", JSON.stringify(token));
        this.appendTokenToHeaderByLocalStorage();
    }

    // append token header from the local storage
    appendTokenToHeaderByLocalStorage() {
        let token = JSON.parse(localStorage.getItem("auth-token"));
        // let user_type = JSON.parse(localStorage.getItem('user_type'))
        if (token != null) {
            this.headers = new HttpHeaders({ Authorization: "Token " + token });
        }
    }

    async login(user_form) {
        this.http
            .post(
                `${this.global.server_url}/main/website/login/for/token/`,
                user_form
            )
            .subscribe(
                (data) => {
                    let server_date_time: any = new Date(data["server_time"]);
                    let system_date_time: any = new Date();
                    let difference_time_in_abs = Math.abs(
                        server_date_time - system_date_time
                    );
                    let dirrent_time_in_minutes = Math.floor(
                        difference_time_in_abs / 1000 / 60
                    );
                    if (dirrent_time_in_minutes > 10) {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.panelClass = "time-mismatch";
                        this.dialog.open(
                            TimeMismatchAlertComponent,
                            dialogConfig
                        );
                    } else {
                        localStorage.setItem(
                            "TOKEN_KEY",
                            JSON.stringify(data["token"])
                        );
                        localStorage.setItem(
                            "first_name",
                            JSON.stringify(data["first_name"])
                        );
                        localStorage.setItem(
                            "user_type_id",
                            JSON.stringify(data["user_type"])
                        );
                        localStorage.setItem(
                            "mobile",
                            JSON.stringify(data["mobile"])
                        );
                        localStorage.setItem(
                            "user_id",
                            JSON.stringify(data["user_id"])
                        );
                        localStorage.setItem(
                            "business_type_wise_order_category_list",
                            JSON.stringify(
                                data["business_type_wise_order_category_list"]
                            )
                        );
                        this.authenticationService.authendicationState.next(
                            true
                        );
                        this.authenticationService.login_event.emit(
                            data["token"]
                        );
                        if (data["user_type"] === 2) {
                            if (data["is_mobile_verfied"]) {
                                this.router.navigateByUrl("/agent/order/form");
                            } else {
                                this.router.navigateByUrl(
                                    "/mobile/number/verification"
                                );
                            }
                            // if (data['is_mobile_verfied']) {
                            //     if (data['business_type_wise_order_category_list'].length == 1) {
                            //         let selected_order_category_id = data['business_type_wise_order_category_list'][0]['order_category_id'];
                            //         localStorage.setItem('selected_order_category_id', JSON.stringify(selected_order_category_id));
                            //         this.router.navigateByUrl('/agent/order/form');
                            //     } else {
                            //         this.openOrderCategoryPopup(data['business_type_wise_order_category_list']);
                            //     }
                            // } else {
                            //     this.router.navigateByUrl('/mobile/number/verification');
                            // }
                        } else if (data["user_type"] === 3) {
                            if (data["is_mobile_verfied"]) {
                                this.router.navigateByUrl(
                                    "/icustomer/order/form"
                                );
                            } else {
                                this.router.navigateByUrl(
                                    "/mobile/number/verification"
                                );
                            }
                        }
                    }
                },
                (error) => {
                    console.error(error);
                    const error_message = JSON.stringify(error["detail"]);
                    alert("Incorrect username or password");
                }
            );
    }

    async openOrderCategoryPopup(data_list) {
        const dialogRef = this.dialog.open(OrderCategorySelectionComponent, {
            height: "500px",
            width: "600px",
            data: {
                order_category_list: data_list,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            const res = result;
        });
    }

    async getCurrentDate() {
        return this.http
            .get(`${this.global.server_url}/main/serve/current/date/`)
            .toPromise()
            .then((current_date) => {
                return current_date;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    serveBusinessTypes() {
        return this.http.get(
            `${this.global.server_url}/main/serve/business/type/`
        );
    }

    serve_general_data_for_registration() {
        return this.http.get(
            `${this.global.server_url}/main/serve/general/data/for/registration/`
        );
    }

    getAllbusinessForBothChange() {
        return this.http.get(
            `${this.global.server_url}/main/serve/all/business/for/booth/change/`
        );
    }

    checkCustomerExists(data) {
        return this.http.post(
            `${this.global.server_url}/main/check/wheather/customer/exists/`,
            data,
            { headers: this.headers }
        );
    }

    otpValidationForTempRegistration(data) {
        return this.http.post(
            `${this.global.server_url}/main/otp/validation/for/temporary/registration/`,
            data,
            { headers: this.headers }
        );
    }

    registerCustomer(data) {
        return this.http.post(
            `${this.global.server_url}/main/register/customer/`,
            data,
            { headers: this.headers }
        );
    }

    serveBoothListUnderPincode(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/booth/under/pincode/`,
            data,
            { headers: this.headers }
        );
    }

    linkBoothWithCustomer(data) {
        return this.http.post(
            `${this.global.server_url}/main/link/booth/with/customer/`,
            data,
            { headers: this.headers }
        );
    }

    serveSelecetedBoothDetails(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/selected/booth/details/`,
            data,
            { headers: this.headers }
        );
    }

    saveAgentRequestForm(data) {
        return this.http.post(
            `${this.global.server_url}/main/save/agent/request/form/`,
            data,
            { headers: this.headers }
        );
    }

    serveBoothLatLng() {
        return this.http.get(
            `${this.global.server_url}/main/serve/booth/lat/lng/`,
            { headers: this.headers }
        );
    }

    serveProductListForICustomer(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/product/list/for/icustomer/`,
            data,
            { headers: this.headers }
        );
    }

    serveLastMonthOrderForICustomer(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/last/month/order/for/icustomer/`,
            data,
            { headers: this.headers }
        );
    }

    registerOrderForICustomer(data) {
        return this.http.post(
            `${this.global.server_url}/main/register/order/for/icustomer/`,
            data,
            { headers: this.headers }
        );
    }

    generatePaymentLinkForIcustomerOrder(data) {
        return this.http.post(
            `${this.global.server_url}/main/generate/payment/link/for/icustmer/order/`,
            data,
            { headers: this.headers }
        );
    }

    serveCustomerBusinessDetails(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/customer/business/details/`,
            data,
            { headers: this.headers }
        );
    }

    serveIcustomerHistory(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/icustomer/latest/twelve/orders/`,
            data,
            { headers: this.headers }
        );
    }

    serveSelectedMonthSaleForIcustomer(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/icustomer/selected/month/sale/group/`,
            data,
            { headers: this.headers }
        );
    }

    getPhotosInsideAlbum(data) {
        return this.http.post(
            `${this.global.server_url}/main/get/photos/inside/album/`,
            data,
            { headers: this.headers }
        );
    }

    getUserManual(data) {
        return this.http.post(
            `${this.global.server_url}/main/get/user/manual/`,
            data,
            { headers: this.headers }
        );
    }

    getGooglePhotos() {
        return this.http.get(
            `${this.global.server_url}/main/get/google/album/`
        );
    }

    serveActiveCarrier() {
        return this.http.get(
            `${this.global.server_url}/main/serve/active/carrier/`
        );
    }

    serveActiveTender() {
        return this.http.get(
            `${this.global.server_url}/main/serve/active/tender/`
        );
    }

    getLastDateForAgentOrder(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/last/date/for/agent/order/`,
            data,
            { headers: this.headers }
        );
    }

    // Booth Agent Due Amount

    getBoothAgentWalletAmount() {
        return this.http.get(
            `${this.global.server_url}/main/business/wise/wallet/amount/`,
            { headers: this.headers }
        );
    }

    getProductList(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/product/list/`,
            data,
            { headers: this.headers }
        );
    }

    serveSingleSalegroup(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/sale/for/selected/date/`,
            data,
            { headers: this.headers }
        );
    }

    updateSelectedSaleGroup(data) {
        return this.http.post(
            `${this.global.server_url}/main/update/selected/sale/group/`,
            data,
            { headers: this.headers }
        );
    }

    updateAndPaySelectedSaleGroup(data) {
        return this.http.post(
            `${this.global.server_url}/main/update/and/pay/selected/sale/group/`,
            data,
            { headers: this.headers }
        );
    }

    getRouteIndentStatusPerSessionForWebsite(data) {
        return this.http.post(
            `${this.global.server_url}/main/get/route/indent/status/per/session/for/website/`,
            data,
            { headers: this.headers }
        );
    }

    checkOrderExists(data) {
        return this.http.post(
            `${this.global.server_url}/main/check/order/exists/`,
            data,
            { headers: this.headers }
        );
    }

    serveBoothOrderHistoryForFuture(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/sale/details/for/future/week/`,
            data,
            { headers: this.headers }
        );
    }

    registerOrderForAgent(data) {
        return this.http.post(
            `${this.global.server_url}/main/register/order/for/agent/`,
            data,
            { headers: this.headers }
        );
    }

    checkIndentStatus(data) {
        return this.http.post(
            `${this.global.server_url}/main/check/indent/status/for/online/`,
            data,
            { headers: this.headers }
        );
    }

    serveOrderCategoryForOrder(data) {
        return this.http.post(
            `${this.global.server_url}/byproducts/serve/order/category/for/order/`,
            data,
            { headers: this.headers }
        );
    }

    deleteSelectedOrder(data) {
        return this.http.post(
            `${this.global.server_url}/main/delete/selected/order/from/website/`,
            data,
            { headers: this.headers }
        );
    }

    servePaymentGatewayList(dcmpu_id: string) {
        return this.http
            .get(
                this.global.server_url +
                    `/payment-gateway/list?dcmpu_id=${dcmpu_id}`,
                { headers: this.headers }
            )
            .pipe(
                catchError((error) => {
                    if (error.status === 400) {
                        console.error("Bad Request", error);
                        alert(error["error"]["message"]);
                    }
                    // Return an empty observable to keep the stream alive
                    throw error;
                })
            );
    }

    serveEncryptionKey() {
        return this.http.get(
            `${this.global.server_url}/main/create/encryption/text/and/make/request/`,
            { headers: this.headers }
        );
    }

    serveEncryptionKeyForTempSale(data) {
        return this.http.post(
            `${this.global.server_url}/main/create/encryption/text/and/make/request/`,
            data,
            { headers: this.headers }
        );
    }

    confirmMobileVerfication(data) {
        return this.http.post(
            `${this.global.server_url}/main/confirm/mobile/verification/`,
            data,
            { headers: this.headers }
        );
    }

    sendOtpToMobileNumber(data) {
        return this.http.post(
            `${this.global.server_url}/main/send/otp/to/mobile/number/`,
            data,
            { headers: this.headers }
        );
    }

    confirmOtpCalled(data) {
        return this.http.post(
            `${this.global.server_url}/main/confirm/otp/for/mobile/verification/`,
            data,
            { headers: this.headers }
        );
    }

    confirmOtpCalledForCustomerVerification(data) {
        return this.http.post(
            `${this.global.server_url}/main/confirm/otp/for/customer/verification/`,
            data,
            { headers: this.headers }
        );
    }

    confirmOtpCalledForPasswordChange(data) {
        return this.http.post(
            `${this.global.server_url}/main/confirm/otp/for/password/change/`,
            data,
            { headers: this.headers }
        );
    }

    updatePasswordForUser(data) {
        return this.http.post(
            `${this.global.server_url}/main/update/password/for/user/`,
            data,
            { headers: this.headers }
        );
    }

    checkCustomerExist(data) {
        return this.http.post(
            `${this.global.server_url}/main/check/icustomer/details/`,
            data,
            { headers: this.headers }
        );
    }

    updateKycForIcustomer(data) {
        return this.http.post(
            `${this.global.server_url}/main/update/kyc/for/icustomer/`,
            data,
            { headers: this.headers }
        );
    }

    checkEditOrderPermission(data) {
        return this.http.post(
            `${this.global.server_url}/main/check/edit/order/permission/`,
            data,
            { headers: this.headers }
        );
    }

    serveAgentMonthlyReport(data) {
        return this.http.post(
            `${this.global.server_url}/main/serve/agent/monthly/report/`,
            data,
            { headers: this.headers }
        );
    }

    usernameVerfication(data) {
        return this.http.post(
            `${this.global.server_url}/main/username/validation/`,
            data
        );
    }

    otpValidation(data) {
        return this.http.post(
            `${this.global.server_url}/main/otp/validation/`,
            data
        );
    }

    resetPassword(data) {
        return this.http.post(
            `${this.global.server_url}/main/reset/password/`,
            data
        );
    }

    checkOrderForNextMonth() {
        return this.http.get(
            `${this.global.server_url}/main/check/next/month/order/is/available/for/customer/`,
            { headers: this.headers }
        );
    }
}

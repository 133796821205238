import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-indivioul-photo',
  templateUrl: './indivioul-photo.component.html',
  styleUrls: ['./indivioul-photo.component.scss']
})
export class IndivioulPhotoComponent implements OnInit {
  photo_url: any;

  constructor(
    public dialogRef: MatDialogRef<IndivioulPhotoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.photo_url = data['selected_photo_url'];
  }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ProductListComponent } from './components/pages/product-list/product-list.component';
import { RegisterIntroComponent } from './components/pages/register/register-intro/register-intro.component';
import { CustomerRegisterComponent } from './components/pages/register/customer-register/customer-register.component';
import { AgentRequestFormComponent } from './components/pages/register/agent-request-form/agent-request-form.component';
import { AgentOrderComponent } from './components/pages/agent/agent-order/agent-order.component';
import { IcustomerOrderComponent } from './components/pages/icustomer/icustomer-order/icustomer-order.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { GalleryInDetailsComponent } from './components/pages/gallery-in-details/gallery-in-details.component';
import { CarrierComponent } from './components/pages/carrier/carrier.component';
import { TenderComponent } from './components/pages/tender/tender.component';
import { PaymentCheckPageComponent } from './components/pages/payment-check-page/payment-check-page.component';
import { MobileNumberVerificationComponent } from './components/pages/mobile-number-verification/mobile-number-verification.component';
import { IcustomerPasswordGenerationComponent } from './components/pages/icustomer-password-generation/icustomer-password-generation.component';
import { IcustomerAadharVerificationComponent } from './components/pages/icustomer/icustomer-aadhar-verification/icustomer-aadhar-verification.component';
import { ManualComponent } from './components/pages/manual/manual.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'team', component: TeamComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'service-details', component: ServiceDetailsComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'project-details', component: ProjectDetailsComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'product', component: ProductListComponent },
  { path: 'register/intro', component: RegisterIntroComponent },
  { path: 'customer/register', component: CustomerRegisterComponent },
  { path: 'agent/request/form', component: AgentRequestFormComponent },
  { path: 'agent/order/form', component: AgentOrderComponent },
  { path: 'icustomer/order/form', component: IcustomerOrderComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'carrier', component: CarrierComponent },
  { path: 'tender', component: TenderComponent },
  { path: 'gallery/in/details', component: GalleryInDetailsComponent },
  { path: 'payment/check', component: PaymentCheckPageComponent },
  { path: 'mobile/number/verification', component: MobileNumberVerificationComponent },
  { path: 'icustomer/password/generation/', component: IcustomerPasswordGenerationComponent },
  { path: 'icustomer/aadhar/verfification/', component: IcustomerAadharVerificationComponent },
  { path: 'user/manual/', component: ManualComponent },
  // { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'privacy-policy', redirectTo: '/assets/privacy-policy.html', pathMatch: 'full' },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-time-mismatch-alert',
    templateUrl: './time-mismatch-alert.component.html',
    styleUrls: ['./time-mismatch-alert.component.scss']
})
export class TimeMismatchAlertComponent implements OnInit {
    today = new Date();

    constructor(private dialogRef: MatDialogRef<TimeMismatchAlertComponent>,) { }

    close() {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

}

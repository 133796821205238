import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/http.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { Router } from '@angular/router';
import { DataTransferService } from 'src/app/data-transfer.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    login_form: FormGroup;
    is_customer_login_selected = false;
    is_agent_login_selected = false;
    is_login_option_selected = true;

    step: 'login' | 'forgot-password' | 'otp-verification' | 'new-password' = 'login';

    forgotPasswordForm: FormGroup;
    otpForm: FormGroup;
    passwordForm: FormGroup;

    isCustomerLogin = false;
    isAgentLogin = false;

    maskedPhone: string = '4800'; // Masked phone number (dynamic value)
    otpError: boolean = false;
    user_id: any;
    mobile: any;
    enable_otp_section: boolean;

    constructor(
        private dialogRef: MatDialogRef<LoginComponent>,
        private router: Router,
        private dataTransfer: DataTransferService,
        @Inject(MAT_DIALOG_DATA) data,
        private formBuilder: FormBuilder,
        private http: HttpService,
        private authenticationService: AuthenticationService
    ) {
        this.login_form = this.formBuilder.group({
            user_name: [null, Validators.required],
            password: [null, Validators.required],
        });

        this.authenticationService.login_event.subscribe((data) => {
            this.dialogRef.close();
        });

        // Forgot Password form
        this.forgotPasswordForm = this.formBuilder.group({
            username: ['', [Validators.required]],
        });

        // OTP form
        this.otpForm = this.formBuilder.group({
            otp: ['', [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(6),
                Validators.pattern('^[0-9]*$')  // This ensures that only numeric values are allowed
            ]],
        });


        // Password form
        this.passwordForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required]],
        });
    }

    customerLoginSelected() {
        this.is_customer_login_selected = true;
        this.is_login_option_selected = false;
        this.is_agent_login_selected = false;
        this.step = 'login'; // Ensure that we are back to the login screen
    }

    AgentLoginSelected() {
        this.is_agent_login_selected = true;
        this.is_login_option_selected = false;
        this.is_customer_login_selected = false;
        this.step = 'login'; // Ensure that we are back to the login screen
    }




    ngOnInit() { }

    get formControls() { return this.login_form.controls; }

    close() {
        this.dialogRef.close();
    }

    logIn() {
        if (this.login_form.invalid) {
            return;
        }
        this.http.login(this.login_form.value);
    }

    passwordGenerationCalled() {
        this.dialogRef.close();
        this.router.navigateByUrl('icustomer/password/generation/')
    }

    userManualCalled(manual) {
        this.dialogRef.close();
        this.dataTransfer.userManual(manual);
        this.router.navigateByUrl('user/manual/');
        this.authenticationService.user_manual.emit();
    }

    // Navigate to Forgot Password screen
    goToForgotPassword() {
        // Hide all sections except Forgot Password
        this.is_login_option_selected = false;
        this.is_customer_login_selected = false;
        this.is_agent_login_selected = false;
        this.step = 'forgot-password'; // Set the step to display only Forgot Password screen
    }



    // Handle Forgot Password (username submission)
    onSubmitUsername() {
        if (this.forgotPasswordForm.invalid) return;

        const username = this.forgotPasswordForm.value.username;
        const data_dict = {
            user_name: username
        };

        this.http.usernameVerfication(data_dict).subscribe((response) => {
            if (response === 'user does not exist') {
                this.step = 'otp-verification';
                this.maskedPhone = response['maskedPhone'];
                this.forgotPasswordForm.value.username = null;
                alert('Incorrect username!');
            }

            else {
                this.user_id = response['user_id'];
                this.mobile = response['mobile'];
                this.step = 'otp-verification'; // Move to the OTP Verification screen

            }


        }, (error) => {
            console.error(error);
            let error_messge = error.error;
            this.forgotPasswordForm.value.username = null;
            alert(error_messge.message);
        });
    }

    verifyOtp() {
        if (this.otpForm.invalid) return;

        // const otp = this.otpForm.value.otp;
        const otp = parseInt(this.otpForm.value.otp, 10);


        let data_dict = {
            'otp': otp,
            'user_id': this.user_id
        };

        this.http.otpValidation(data_dict).subscribe((response) => {

            if (response === 'OTP does Not Match') {
                // this.step = 'new-password';
                this.maskedPhone = response['maskedPhone'];
                this.otpForm.value.username = null;
                // alert('Incorrect otp!');
            }

            else {
                // this.user_id = response['user_id'];
                // this.mobile = response['mobile'];
                this.step = 'new-password'; // Move to the new password screen

            }


        }, (error) => {
            console.error(error);
            let error_messge = error.error;
            this.otpForm.value.username = null;
            alert(error_messge.message);
        });
    }



    submitNewPassword() {
        const newPassword = this.passwordForm.value.newPassword;

        let data_dict = {
            'raw_password': newPassword,
            'user_id': this.user_id
        };

        // Call API to reset the password
        this.http.resetPassword(data_dict).subscribe(
            (response) => {
                alert('Password changed successfully');
                this.passwordForm.reset();  // Reset the form after success
                this.is_login_option_selected = true; // Show login options
                this.step = 'login'; // Hide the new-password step
                this.router.navigateByUrl('');  // Navigate to the homepage
            },
            (error) => {
                console.error(error); // Log error for debugging
                alert('An error occurred while resetting the password. Please try again later.');
            }
        );
    }



}

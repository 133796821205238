import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { DataTransferService } from 'src/app/data-transfer.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    album_list: any;
    is_data_available = false;
    constructor(private httpService: HttpService, private dataTransfer: DataTransferService, private router: Router) {
        this.is_data_available = false;
        this.httpService.getGooglePhotos().subscribe((data) => {
            this.is_data_available = true;
            this.album_list = data['albums'];
        }, (error) => {
            this.is_data_available = true;
            console.error(error);
        });
    }

    albumCliked(album_obj) {
        this.dataTransfer.albumSelected(album_obj);
        this.router.navigateByUrl('/gallery/in/details');
    }

    ngOnInit() {
    }

}

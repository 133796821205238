import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
@Injectable({
    providedIn: 'root'
})

export class CustomerRegisterValidator {
    constructor() { }
    static checkPhoneNumber(control: FormControl): any {
        if (control.hasOwnProperty('_parent')) {
            if (control.value !== null && control.value !== '') {
                const mobile = Math.ceil(Math.log(control.value + 1) / Math.LN10);
                if (mobile > 12) {
                    return { 'error': 'Mobile Number Should not be Greater than 12' };
                } else if (mobile < 10) {
                    return { 'error': 'Mobile Number Should not be less than 10' };
                }
            }
        }
    }
    static checkPincode(control: FormControl): any {
        if (control.hasOwnProperty('_parent')) {
            if (control.value !== null && control.value !== '') {
                const pincode = Math.ceil(Math.log(control.value + 1) / Math.LN10);
                if (pincode > 6) {
                    return { 'error': 'Pincode Should not be Greater than 6' };
                } else if (pincode < 6) {
                    return { 'error': 'Pincode Should not be less than 6' };
                }
            }
        }
    }
    static checkAadharNumber(control: FormControl): any {
        if (control.hasOwnProperty('_parent')) {
            if (control.value !== null && control.value !== '') {
                const pincode = Math.ceil(Math.log(control.value + 1) / Math.LN10);
                if (pincode > 12) {
                    return { 'error': 'Aadhar number Should not be Greater than 12' };
                } else if (pincode < 12) {
                    return { 'error': 'Aadhar number Should not be less than 12' };
                }
            }
        }
    }

    static checkEmail(control: FormControl): any {
        if (control.hasOwnProperty('_parent')) {
            if (control.value !== null && control.value !== '') {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(control.value)) {
                    return true;
                } else {
                    return { 'error': 'Enter valid email' };
                }
            }
        }
    }

    static checkConfirmPassword(control: FormControl): any {
        if (control.hasOwnProperty('_parent')) {
            let password = control['_parent']['controls']['password']['value'];
            if (password !== null && password !== '') {
                if (control.value !== null && control.value !== '') {
                    if (control.value === control['_parent']['controls']['password']['value']) {
                        return true;
                    } else {
                        return { 'error': '*passwords does not match' };
                    }
                }
            }
        }
    }
}




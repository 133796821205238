import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../../pages/login/login.component';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class FooterComponent implements OnInit {

    location: any;
    currentYear: any;
    is_logged = false;
    constructor(private router: Router, location: Location, private dialog: MatDialog, private authenticationService: AuthenticationService) {
        this.authenticationService.login_event.subscribe((data) => {
            this.is_logged = true;
        });
        this.authenticationService.logout_event.subscribe((data) => {
            this.is_logged = false;
        });
    }
    loginPageClicked() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'login-modal-class';
        this.dialog.open(LoginComponent, dialogConfig);
    }
    ngOnInit() {
        this.currentYear = new Date().getFullYear();
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                }
            });
    }

}

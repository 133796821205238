import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { DataTransferService } from 'src/app/data-transfer.service';
import { IndivioulPhotoComponent } from '../indivioul-photo/indivioul-photo.component';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-gallery-in-details',
    templateUrl: './gallery-in-details.component.html',
    styleUrls: ['./gallery-in-details.component.scss']
})
export class GalleryInDetailsComponent implements OnInit {
    album_details: any;
    album_photos_list: any;
    is_data_available = false;
    constructor(private httpService: HttpService, private dataTransfer: DataTransferService, private dialog: MatDialog, ) {
        this.album_details = this.dataTransfer.album_details;
        let data_dict = {
            'albumId': this.album_details['id'],
            "pageSize": 50
        };
        this.is_data_available = false;
        this.httpService.getPhotosInsideAlbum(data_dict).subscribe((data) => {
            this.album_photos_list = data['mediaItems'];
            this.is_data_available = true;
        }, (error) => {
            this.is_data_available = false;
            console.error(error);
        });
    }

    photoClicked(selected_photo_url) {

        const dialogRef = this.dialog.open(IndivioulPhotoComponent, {
            data: {
                'selected_photo_url': selected_photo_url
            },
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
    ngOnInit() {
    }

}

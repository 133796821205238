import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/http.service";
import { BoothChangeForOrderComponent } from "../booth-change-for-order/booth-change-for-order.component";
import { MatDialog } from "@angular/material/dialog";
import { PasswordChangeOptionComponent } from "src/app/component/pages/password-change-option/password-change-option.component";
import { IcustomerAadharVerificationComponent } from "../icustomer-aadhar-verification/icustomer-aadhar-verification.component";
import { catchError } from "rxjs/operators";

@Component({
    selector: "app-icustomer-order",
    templateUrl: "./icustomer-order.component.html",
    styleUrls: ["./icustomer-order.component.scss"],
})
export class IcustomerOrderComponent implements OnInit {
    product_form_dict: any = { total_price_per_order: 0 };
    product_list: any;
    session_list: any;
    final_price: any;
    total_days_in_next_month: number;
    today: any;
    monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    next_month_value_in_number: number;
    next_month_start_date_in_string: any;
    business_details: any;
    pincode: any;
    product_availability: any;
    icustomer_id: any = null;
    icustomer_verified = false;
    icustomer_detail: any;
    milk_limit: any;
    total_quantity: any = 0;
    old_employee_product_list: any;
    icustomer_type_id: any;
    showerror: boolean = false;
    icustomer_code: any = null;
    icustomer_mobile: any = null;
    is_counter_selected: boolean = false;
    counter_list: any;
    collection_center: any = [];
    logged_user_befere_select_counter: any;
    sale_group_data: any;
    login_users: any;
    total_amount_in_counter: any;
    counter_name: any;
    today_date_time: any = new Date();
    future_order: any;
    month_and_year: any = null;
    selected_index: any = null;
    selected_date: string;
    total_days_in_month: number;
    product_form_dict_for_future_order: {};
    business_details_for_future: any;
    current_month_for_future_order_check = new Date().getMonth() + 1;
    current_year_for_future_order_check = new Date().getFullYear();
    user_profile_id: any;
    two_month_after_from_current_month: string;
    last_month_from_next_order: Date;
    order_expiry_date: any;
    paymentGateways: any[] = null;
    selectedGateway: any = null;

    constructor(private httpService: HttpService, private dialog: MatDialog) {
        let today = new Date();
        this.today = today.toISOString().split("T")[0];
        this.serveProductList();
        this.servePaymentGateways();
    }

    async serveProductList() {
        const data_dict = {
            from: "website",
        };
        this.httpService.serveProductListForICustomer(data_dict).subscribe(
            (data) => {
                this.product_list = data["product_list"];
                this.session_list = data["session_list"];
                this.product_availability = data["product_availability"];
                this.milk_limit = data["milk_limit"];
                this.old_employee_product_list =
                    data["old_employee_product_list"];
                this.order_expiry_date = data["order_expiry_date"];

                this.icustomer_type_id = data["icustomer_type_id"];
                this.httpService
                    .serveLastMonthOrderForICustomer(data_dict)
                    .subscribe(
                        (last_date_data) => {
                            this.serveBusinessDetails();
                            this.icustomer_detail = last_date_data;
                            this.pincode = last_date_data["pincode"];
                            this.user_profile_id =
                                last_date_data["user_profile_id"];
                            let last_date_product_data =
                                last_date_data["last_date_product_data"];
                            if (last_date_data["is_exists"]) {
                                let date = new Date();
                                if (
                                    last_date_data["next_date_from_last_date"] <
                                    this.today
                                ) {
                                    this.last_month_from_next_order =
                                        new Date();
                                    date = new Date();
                                } else {
                                    this.last_month_from_next_order = new Date(
                                        last_date_data[
                                            "next_date_from_last_date"
                                        ]
                                    );
                                    date = new Date(
                                        last_date_data[
                                            "next_date_from_last_date"
                                        ]
                                    );
                                }
                                let next_month_start_date = new Date(
                                    date.setMonth(date.getMonth() + 1, 1)
                                );
                                this.two_month_after_from_current_month =
                                    new Date(
                                        date.setMonth(date.getMonth() + 1, 2)
                                    )
                                        .toISOString()
                                        .split("T")[0];
                                this.next_month_start_date_in_string =
                                    next_month_start_date
                                        .toISOString()
                                        .split("T")[0];
                                this.next_month_value_in_number =
                                    next_month_start_date.getMonth();
                                this.total_days_in_next_month = new Date(
                                    next_month_start_date.getFullYear(),
                                    next_month_start_date.getMonth() + 1,
                                    0
                                ).getDate();
                                // let month_difference = next_month_start_date.getMonth() - new Date().getMonth();
                                if (
                                    new Date().getDate() >
                                    this.order_expiry_date[
                                        this.next_month_value_in_number + 1
                                    ]["date"]
                                ) {
                                    alert(
                                        "Last date for " +
                                            String(
                                                this.monthNames[
                                                    this
                                                        .next_month_value_in_number
                                                ]
                                            ) +
                                            " order is " +
                                            String(
                                                this.monthNames[
                                                    new Date().getMonth()
                                                ]
                                            ) +
                                            " " +
                                            String(
                                                this.order_expiry_date[
                                                    this
                                                        .next_month_value_in_number +
                                                        1
                                                ]["date"] + "."
                                            )
                                    );
                                    // this.show_form = false;
                                    // this.show_order_date_is_expiry = true;
                                    let next_month_start_date = new Date(
                                        date.setMonth(date.getMonth(), 1)
                                    );
                                    this.two_month_after_from_current_month =
                                        new Date(
                                            date.setMonth(
                                                date.getMonth() + 1,
                                                2
                                            )
                                        )
                                            .toISOString()
                                            .split("T")[0];
                                    this.next_month_start_date_in_string =
                                        next_month_start_date
                                            .toISOString()
                                            .split("T")[0];
                                    this.next_month_value_in_number =
                                        next_month_start_date.getMonth();
                                    this.total_days_in_next_month = new Date(
                                        next_month_start_date.getFullYear(),
                                        next_month_start_date.getMonth() + 1,
                                        0
                                    ).getDate();
                                }
                            } else {
                                let today = new Date();
                                let next_month_start_date = new Date(
                                    today.setMonth(today.getMonth() + 1, 1)
                                );
                                this.two_month_after_from_current_month =
                                    new Date(
                                        today.setMonth(today.getMonth() + 1, 2)
                                    )
                                        .toISOString()
                                        .split("T")[0];
                                this.next_month_start_date_in_string =
                                    next_month_start_date
                                        .toISOString()
                                        .split("T")[0];
                                this.next_month_value_in_number =
                                    next_month_start_date.getMonth();
                                this.total_days_in_next_month = new Date(
                                    next_month_start_date.getFullYear(),
                                    next_month_start_date.getMonth() + 1,
                                    0
                                ).getDate();
                                if (
                                    new Date().getDate() >
                                    this.order_expiry_date[
                                        this.next_month_value_in_number + 1
                                    ]["date"]
                                ) {
                                    alert(
                                        "Last date for " +
                                            String(
                                                this.monthNames[
                                                    this
                                                        .next_month_value_in_number
                                                ]
                                            ) +
                                            " order is " +
                                            String(
                                                this.monthNames[
                                                    new Date().getMonth()
                                                ]
                                            ) +
                                            " " +
                                            String(
                                                this.order_expiry_date[
                                                    this
                                                        .next_month_value_in_number +
                                                        1
                                                ]["date"] + "."
                                            )
                                    );
                                    // this.show_form = false;
                                    // this.show_order_date_is_expiry = true;
                                    let next_month_start_date = new Date(
                                        today.setMonth(today.getMonth(), 1)
                                    );
                                    this.two_month_after_from_current_month =
                                        new Date(
                                            today.setMonth(
                                                today.getMonth() + 1,
                                                2
                                            )
                                        )
                                            .toISOString()
                                            .split("T")[0];
                                    this.next_month_start_date_in_string =
                                        next_month_start_date
                                            .toISOString()
                                            .split("T")[0];
                                    this.next_month_value_in_number =
                                        next_month_start_date.getMonth();
                                    this.total_days_in_next_month = new Date(
                                        next_month_start_date.getFullYear(),
                                        next_month_start_date.getMonth() + 1,
                                        0
                                    ).getDate();
                                }
                            }
                            this.product_form_dict = {};
                            this.product_form_dict["product"] = {};
                            this.product_form_dict[
                                "total_price_per_product_per_day"
                            ] = {};
                            this.product_form_dict[
                                "total_price_per_product_per_month"
                            ] = {};
                            this.product_form_dict["total_price_per_session"] =
                                {};
                            this.product_form_dict[
                                "total_quantity_per_session"
                            ] = {};
                            this.product_form_dict["total_price_per_order"] = 0;
                            data["session_list"].forEach((session_element) => {
                                this.product_form_dict["product"][
                                    session_element.id
                                ] = {};
                                this.product_form_dict[
                                    "total_price_per_session"
                                ][session_element.id] = 0;
                                this.product_form_dict[
                                    "total_quantity_per_session"
                                ][session_element.id] = 0;
                                data["product_list"].forEach(
                                    (product_element) => {
                                        this.product_form_dict["product"][
                                            session_element.id
                                        ][product_element.product_id] = {
                                            quantity: null,
                                            price: 0,
                                        };
                                        // this.product_form_dict['total_price_per_product_per_day'][product_element.product_id] = 0;
                                        this.product_form_dict[
                                            "total_price_per_product_per_month"
                                        ][product_element.product_id] = 0;
                                    }
                                );
                            });
                            this.getHistroy();
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
            },
            (error) => {
                console.error(error);
            }
        );
    }

    serveBusinessDetails() {
        const data_dict = {
            from: "website",
        };
        this.httpService.serveCustomerBusinessDetails(data_dict).subscribe(
            (data) => {
                this.business_details = data;
            },
            (error) => {
                console.error(error);
            }
        );
    }

    productQuantityChanged(session_id, product_id, product_price) {
        // calculate overall quatitity
        let temp_quantity =
            this.product_form_dict["product"][session_id][product_id][
                "quantity"
            ];
        if (temp_quantity !== "") {
            this.product_form_dict["product"][session_id][product_id][
                "quantity"
            ] = parseInt(
                this.product_form_dict["product"][session_id][product_id][
                    "quantity"
                ]
            );
        } else {
            this.product_form_dict["product"][session_id][product_id][
                "quantity"
            ] = null;
        }
        this.total_quantity = 0;
        this.session_list.forEach((session_element) => {
            this.product_list.forEach((product_element) => {
                this.total_quantity +=
                    this.product_form_dict["product"][session_element.id][
                        product_element.product_id
                    ]["quantity"] * product_element.product_quantity;
            });
        });
        this.total_quantity = this.total_quantity / 1000;
        let quantity =
            this.product_form_dict["product"][session_id][product_id][
                "quantity"
            ];
        this.product_form_dict["product"][session_id][product_id]["price"] =
            quantity * product_price;
        // add the product session based total price
        let total_cost = 0;
        this.session_list.forEach((session_element) => {
            total_cost +=
                this.product_form_dict["product"][session_element.id][
                    product_id
                ]["price"];
        });
        this.product_form_dict["total_price_per_product_per_month"][
            product_id
        ] = this.total_days_in_next_month * total_cost;
        // add the total product price date wise
        this.product_form_dict["total_price_per_order"] = 0;
        this.product_list.forEach((product_element) => {
            this.product_form_dict["total_price_per_order"] +=
                this.product_form_dict["total_price_per_product_per_month"][
                    product_element.product_id
                ];
        });
        // add total price per session
        let total_cost_per_session = 0;
        this.product_form_dict["total_price_per_session"][session_id] = 0;
        this.product_list.forEach((product_element) => {
            total_cost_per_session +=
                this.product_form_dict["product"][session_id][
                    product_element.product_id
                ]["price"];
        });
        this.product_form_dict["total_price_per_session"][session_id] =
            total_cost_per_session;

        // add the total quantity per session
        let total_quantity_per_session = 0;
        this.product_form_dict["total_quantity_per_session"][session_id] = 0;
        this.product_list.forEach((product_element) => {
            total_quantity_per_session +=
                this.product_form_dict["product"][session_id][
                    product_element.product_id
                ]["quantity"];
        });
        this.product_form_dict["total_quantity_per_session"][session_id] =
            total_quantity_per_session;
    }

    servePaymentGateways() {
        const dcmpu_id = "b8a6803b-edfb-41cf-b2fd-cabcf67a80ff";
        this.httpService.servePaymentGatewayList(dcmpu_id).subscribe((data) => {
            this.paymentGateways = data["data"];
        });
    }

    selectPaymentGateway(gateway) {
        if (!gateway.is_active) {
            return;
        }
        this.selectedGateway = gateway.id;
    }

    async payClicked() {
        if (!this.icustomer_detail["is_aadhar_verified"]) {
            this.openPasswordChangeComponentCalled();
            return false;
        }
        if (this.total_quantity > this.milk_limit) {
            alert("Max limit is reached please check the qunatity");
            return false;
        }
        if (!this.selectedGateway) {
            alert("Please select a payment gateway");
            return false;
        }
        if (confirm("Are you sure to order")) {
            let data_dict = {
                month_start_date: this.next_month_start_date_in_string,
                product_form_data: this.product_form_dict,
                total_days_in_month: this.total_days_in_next_month,
                from: "website",
                payment_gateway_id: this.selectedGateway,
                order_amount: this.product_form_dict["total_price_per_order"],
                // 'icustomer_id': this.icustomer_id
            };
            this.httpService
                .generatePaymentLinkForIcustomerOrder(data_dict)
                .pipe(
                    catchError((error) => {
                        if (error.status === 400) {
                            console.error("Bad Request", error);
                            alert(error["error"]["message"]);
                        }
                        // Return an empty observable to keep the stream alive
                        throw error;
                    })
                )
                .subscribe(
                    (data) => {
                        if (data["method"] === "POST") {
                            const form = document.createElement("form");
                            form.method = "POST";
                            form.action = data["payment_gateway_redirect_url"];
                            const payload = data["payload"];
                            for (const key in payload) {
                                if (payload.hasOwnProperty(key)) {
                                    if (typeof payload[key] === "object") {
                                        for (const subKey in payload[key]) {
                                            if (
                                                payload[key].hasOwnProperty(
                                                    subKey
                                                )
                                            ) {
                                                const hiddenField =
                                                    document.createElement(
                                                        "input"
                                                    );
                                                hiddenField.type = "hidden";
                                                hiddenField.name = `${key}[${subKey}]`;
                                                hiddenField.value =
                                                    payload[key][subKey];
                                                form.appendChild(hiddenField);
                                            }
                                        }
                                    } else {
                                        const hiddenField =
                                            document.createElement("input");
                                        hiddenField.type = "hidden";
                                        hiddenField.name = key;
                                        hiddenField.value = payload[key];
                                        form.appendChild(hiddenField);
                                    }
                                }
                            }
                            document.body.appendChild(form);
                            form.submit();
                        } else {
                            window.location.href =
                                data["payment_gateway_redirect_url"];
                        }
                    },
                    (error) => {
                        // loading.dismiss();
                        console.error(error);
                    }
                );
        }
    }

    getHistroy() {
        const data_dict = {
            from: "website",
        };
        this.httpService.serveIcustomerHistory(data_dict).subscribe(
            (data) => {
                this.future_order = data;
            },
            (error) => {
                console.error(error);
            }
        );
    }

    onPageTitleSelected(index) {
        if (this.selected_index === index) {
            this.selected_index = null;
        } else {
            this.selected_index = index;
        }
        // this.selected_heading_index = null;
    }

    routeToDetailPage(month_and_year, index) {
        this.onPageTitleSelected(index);
        this.selected_date =
            month_and_year["year"] + "-" + month_and_year["month"] + "-" + "01";
        this.serveCustomerFutureOrders();
        this.month_and_year = month_and_year;
    }

    async serveCustomerFutureOrders() {
        let data_dict = {
            selected_date: this.selected_date,
            from: "website",
            // 'icustomer_id': this.icustomer_id
        };
        this.httpService
            .serveSelectedMonthSaleForIcustomer(data_dict)
            .subscribe(
                (product_data) => {
                    let date = new Date(this.selected_date);
                    this.total_days_in_month = new Date(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        0
                    ).getDate();
                    this.product_form_dict_for_future_order = {};
                    this.product_form_dict_for_future_order["product"] = {};
                    this.product_form_dict_for_future_order[
                        "total_price_per_product_per_day"
                    ] = {};
                    this.product_form_dict_for_future_order[
                        "total_price_per_product_per_month"
                    ] = {};
                    this.product_form_dict_for_future_order[
                        "total_price_per_session"
                    ] = {};
                    this.business_details_for_future =
                        product_data["business_details"];
                    // this.product_form_dict_for_future_order['total_price_per_order'] = 0;
                    this.session_list.forEach((session_element) => {
                        this.product_form_dict_for_future_order["product"][
                            session_element.id
                        ] = {};
                        if (
                            product_data["product"].hasOwnProperty(
                                session_element.id
                            )
                        ) {
                            this.product_list.forEach((product_element) => {
                                if (
                                    product_data["product"][
                                        session_element.id
                                    ].hasOwnProperty(product_element.product_id)
                                ) {
                                    this.product_form_dict_for_future_order[
                                        "product"
                                    ][session_element.id][
                                        product_element.product_id
                                    ] =
                                        product_data["product"][
                                            session_element.id
                                        ][product_element.product_id];
                                } else {
                                    this.product_form_dict_for_future_order[
                                        "product"
                                    ][session_element.id][
                                        product_element.product_id
                                    ] = {
                                        quantity: null,
                                        price: 0,
                                    };
                                }
                            });
                        } else {
                            // this.product_form_dict_for_future_order['total_price_per_session'][session_element.id] = 0;
                            this.product_list.forEach((product_element) => {
                                this.product_form_dict_for_future_order[
                                    "product"
                                ][session_element.id][
                                    product_element.product_id
                                ] = {
                                    quantity: null,
                                    price: 0,
                                };
                            });
                        }
                        this.product_list.forEach((product_element) => {
                            if (
                                product_data[
                                    "total_price_per_product"
                                ].hasOwnProperty(product_element.product_id)
                            ) {
                                this.product_form_dict_for_future_order[
                                    "total_price_per_product_per_month"
                                ][product_element.product_id] =
                                    product_data["total_price_per_product"][
                                        product_element.product_id
                                    ] * this.total_days_in_month;
                            } else {
                                this.product_form_dict_for_future_order[
                                    "total_price_per_product_per_month"
                                ][product_element.product_id] = 0;
                            }
                        });
                    });
                    this.product_form_dict_for_future_order[
                        "total_price_per_order"
                    ] = 0;
                    this.product_list.forEach((product_element) => {
                        this.product_form_dict_for_future_order[
                            "total_price_per_order"
                        ] +=
                            this.product_form_dict_for_future_order[
                                "total_price_per_product_per_month"
                            ][product_element.product_id];
                    });
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    async boothChangeClicked(page_action) {
        const dialogRef = this.dialog.open(BoothChangeForOrderComponent, {
            height: "600px",
            width: "800px",
            data: {
                pincode: this.pincode,
                user_profile_id: this.user_profile_id,
                page_action: page_action,
                selected_date: this.month_and_year,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            const res = result;
            if (res) {
                if (page_action === "register") {
                    this.serveBusinessDetails();
                } else {
                    this.serveCustomerFutureOrders();
                }
            }
        });
    }

    enterKeyCalled(session_id, product_index) {
        if (
            session_id === 2 &&
            product_index + 1 === this.product_list.length
        ) {
            if (document.getElementById("confirm_button") !== null) {
                document.getElementById("confirm_button").focus();
            }
        } else {
            if (session_id === 1) {
                session_id = 2;
            } else {
                session_id = 1;
                product_index = product_index + 1;
            }
            if (
                !this.product_availability[session_id][
                    this.product_list[product_index]["product_id"]
                ]
            ) {
                this.enterKeyCalled(session_id, product_index);
            } else {
                let next_element_id = session_id + "_" + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    leftArrowKeyCalled(session_id, product_index) {
        if (session_id === 1 && product_index === 0) {
        } else {
            if (session_id === 1) {
                session_id = 2;
                product_index -= 1;
            } else {
                session_id = 1;
            }
            let session_obj = this.session_list.filter((element) => {
                return element.id === session_id;
            });
            if (
                !this.product_availability[session_id][
                    this.product_list[product_index]["product_id"]
                ]
            ) {
                this.leftArrowKeyCalled(session_id, product_index);
            } else {
                let next_element_id = session_id + "_" + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    upArrowKeyCalled(session_id, product_index) {
        if (product_index === 0) {
        } else {
            product_index -= 1;
            if (
                !this.product_availability[session_id][
                    this.product_list[product_index]["product_id"]
                ]
            ) {
                this.upArrowKeyCalled(session_id, product_index);
            } else {
                let next_element_id = session_id + "_" + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    rightArrowKeyCalled(session_id, product_index) {
        if (
            session_id === 2 &&
            product_index + 1 === this.product_list.length
        ) {
            if (document.getElementById("confirm_button") !== null) {
                document.getElementById("confirm_button").focus();
            }
        } else {
            if (session_id === 1) {
                session_id = 2;
            } else {
                session_id = 1;
                product_index = product_index + 1;
            }
            if (
                !this.product_availability[session_id][
                    this.product_list[product_index]["product_id"]
                ]
            ) {
                this.rightArrowKeyCalled(session_id, product_index);
            } else {
                let next_element_id = session_id + "_" + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    downArrowKeyCalled(session_id, product_index) {
        if (product_index + 1 === this.product_list.length) {
            if (document.getElementById("confirm_button") !== null) {
                document.getElementById("confirm_button").focus();
            }
        } else {
            product_index += 1;
            if (
                !this.product_availability[session_id][
                    this.product_list[product_index]["product_id"]
                ]
            ) {
                this.downArrowKeyCalled(session_id, product_index);
            } else {
                let next_element_id = session_id + "_" + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    eventEmitOnInput(session_id, product_index, event) {
        let key_code = event.keyCode;
        if (key_code === 13) {
            this.enterKeyCalled(session_id, product_index);
        } else if (key_code === 37) {
            this.leftArrowKeyCalled(session_id, product_index);
        } else if (key_code === 38) {
            this.upArrowKeyCalled(session_id, product_index);
        } else if (key_code === 39) {
            this.rightArrowKeyCalled(session_id, product_index);
        } else if (key_code === 40) {
            this.downArrowKeyCalled(session_id, product_index);
        }
    }

    passwordChangeCalled() {
        const dialogRef = this.dialog.open(PasswordChangeOptionComponent, {
            height: "500px",
            width: "600px",
            data: {},
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            const res = result;
        });
    }

    openPasswordChangeComponentCalled() {
        const dialogRef = this.dialog.open(
            IcustomerAadharVerificationComponent,
            {
                height: "500px",
                width: "600px",
                data: { data: this.icustomer_detail },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            const res = result;
            if (res) {
                this.icustomer_detail["is_aadhar_verified"] = true;
            }
        });
    }

    ngOnInit() {}
}

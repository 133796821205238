import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HttpService } from "src/app/http.service";
import { GlobalService } from "src/app/global.service";
import { catchError } from "rxjs/operators";

@Component({
    selector: "app-agent-confirm-order",
    templateUrl: "./agent-confirm-order.component.html",
    styleUrls: ["./agent-confirm-order.component.scss"],
})
export class AgentConfirmOrderComponent implements OnInit {
    final_price: any;
    wallet_balance: any;
    business_type_id: any;
    order_details: any;
    is_wallet_selected = false;
    final_customer_wallet: any = null;
    final_price_after_wallet: any;
    order_date_list: any;
    selected_business_code: any;
    disable_button = false;
    is_session_wise_edit = null;
    order_category: any = {};
    selected_order_category_id = 1;
    extra_amount: any;
    extra_milk_qty: any;
    extra_curd_qty: any;
    paymentGateways: any[] = null;
    selectedGateway: any = null;

    constructor(
        public dialogRef: MatDialogRef<AgentConfirmOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private http: HttpService,
        private global: GlobalService
    ) {
        console.info(data);
        this.final_price = data["final_price"];
        this.order_date_list = data["order_dates"];
        this.wallet_balance = data["wallet_balance"];
        this.selected_business_code = data["business_code"];
        this.business_type_id = data["business_type_id"];
        this.is_session_wise_edit = data["is_session_wise_edit"];
        this.order_details = data["order_details"];
        this.extra_amount = data["extra_amount"];
        this.extra_milk_qty = data["extra_milk_qty"];
        this.extra_curd_qty = data["extra_allowance_qty"];
        if (this.business_type_id === 3 || this.business_type_id === 5) {
            this.is_wallet_selected = true;
            if (this.wallet_balance > 0) {
                this.final_price_after_wallet =
                    data["final_price"] -
                    data["wallet_balance"] +
                    data["extra_amount"];
                this.final_customer_wallet =
                    data["wallet_balance"] -
                    data["final_price"] -
                    data["extra_amount"];

                if (this.final_customer_wallet <= 0) {
                    this.final_customer_wallet = 0;
                }
                if (this.final_price_after_wallet <= 0) {
                    this.final_price_after_wallet = 0;
                }
            } else {
                this.final_price_after_wallet =
                    data["final_price"] + data["extra_amount"];
                this.is_wallet_selected = false;
            }
        } else {
            this.final_price_after_wallet =
                data["final_price"] + data["extra_amount"];
            this.final_customer_wallet = data["wallet_balance"];
            this.is_wallet_selected = false;
        }
        this.serveOrderCategory();
        this.servePaymentGateways();
    }

    async serveOrderCategory() {
        let data_dict = {
            from: "website",
        };
        this.http.serveOrderCategoryForOrder(data_dict).subscribe(
            (data) => {
                this.order_category = data;
            },
            (error) => {
                console.error(error);
            }
        );
    }

    walletCheckboxChanged(event) {
        if (!event.checked) {
            this.final_price_after_wallet = this.final_price;
            this.final_customer_wallet = this.wallet_balance;
        } else {
            this.final_customer_wallet =
                this.wallet_balance - this.final_price_after_wallet;
            this.final_price_after_wallet -= this.wallet_balance;
            if (this.final_price_after_wallet <= 0) {
                this.final_price_after_wallet = 0;
            }
            if (this.final_customer_wallet <= 0) {
                this.final_customer_wallet = 0;
            }
        }
    }

    payClicked() {
        let session_list = [];
        if (this.is_session_wise_edit[1]) {
            session_list.push(1);
        }
        if (this.is_session_wise_edit[2]) {
            session_list.push(2);
        }
        let data_dict = {
            business_code: this.selected_business_code,
            order_date: this.order_date_list[0],
            session_list: session_list,
        };
        this.http
            .checkIndentStatus(data_dict)

            .subscribe(
                (data) => {
                    if (data) {
                        let data_dict = {
                            order_date: this.order_date_list,
                            product_form_details: this.order_details,
                            final_customer_wallet: this.final_customer_wallet,
                            final_price: this.final_price_after_wallet,
                            from: "website",
                            business_code: this.selected_business_code,
                            business_type_id: this.business_type_id,
                            is_wallet_selected: this.is_wallet_selected,
                        };
                        this.disable_button = true;
                        this.http
                            .registerOrderForAgent(data_dict)
                            .pipe(
                                catchError((error) => {
                                    if (error.status === 400) {
                                        console.error("Bad Request", error);
                                        alert(error["error"]["message"]);
                                        this.closeComponent(false, false);
                                    }
                                    // Return an empty observable to keep the stream alive
                                    throw error;
                                })
                            )
                            .subscribe(
                                (data) => {
                                    this.final_price = null;
                                    this.order_date_list = null;
                                    this.global.showSnakeBar(
                                        "order saved success"
                                    );
                                    this.closeComponent(true, false);
                                    this.disable_button = false;
                                },
                                (error) => {
                                    this.disable_button = false;
                                    this.closeComponent(false, false);
                                    console.error(error);
                                }
                            );
                    } else {
                        alert("Indent taken for this route you cannot order");
                        this.disable_button = false;
                        this.closeComponent(false, true);
                        return false;
                    }
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    servePaymentGateways() {
        const dcmpu_id = "b8a6803b-edfb-41cf-b2fd-cabcf67a80ff";
        this.http.servePaymentGatewayList(dcmpu_id).subscribe((data) => {
            this.paymentGateways = data["data"];
        });
    }

    selectPaymentGateway(gateway) {
        if (!gateway.is_active) {
            return;
        }
        this.selectedGateway = gateway.id;
        console.log(this.selectedGateway);
    }

    payViaOnlineCalled() {
        let session_list = [];
        if (this.is_session_wise_edit[1]) {
            session_list.push(1);
        }
        if (this.is_session_wise_edit[2]) {
            session_list.push(2);
        }
        let data_dict = {
            business_code: this.selected_business_code,
            order_date: this.order_date_list[0],
            session_list: session_list,
        };
        this.http.checkIndentStatus(data_dict).subscribe(
            (data) => {
                if (data) {
                    if (this.final_price_after_wallet === 0) {
                        this.payClicked();
                    } else {
                        let data_dict = {
                            order_date: this.order_date_list,
                            product_form_details: this.order_details,
                            final_customer_wallet: this.final_customer_wallet,
                            final_price: this.final_price_after_wallet,
                            from: "website",
                            business_code: this.selected_business_code,
                            business_type_id: this.business_type_id,
                            is_wallet_selected: this.is_wallet_selected,
                            extra_milk_qty: this.extra_milk_qty,
                            extra_curd_qty: this.extra_curd_qty,
                            payment_gateway_id: this.selectedGateway,
                            order_amount: this.final_price,
                        };
                        this.disable_button = true;
                        this.http
                            .serveEncryptionKeyForTempSale(data_dict)
                            .pipe(
                                catchError((error) => {
                                    if (error.status === 400) {
                                        console.error("Bad Request", error);
                                        alert(error["error"]["message"]);
                                        this.closeComponent(false, false);
                                    }
                                    // Return an empty observable to keep the stream alive
                                    throw error;
                                })
                            )
                            .subscribe(
                                (data) => {
                                    if (data["method"] === "POST") {
                                        const form =
                                            document.createElement("form");
                                        form.method = "POST";
                                        form.action =
                                            data[
                                                "payment_gateway_redirect_url"
                                            ];
                                        const payload = data["payload"];
                                        for (const key in payload) {
                                            if (payload.hasOwnProperty(key)) {
                                                if (
                                                    typeof payload[key] ===
                                                    "object"
                                                ) {
                                                    for (const subKey in payload[
                                                        key
                                                    ]) {
                                                        if (
                                                            payload[
                                                                key
                                                            ].hasOwnProperty(
                                                                subKey
                                                            )
                                                        ) {
                                                            const hiddenField =
                                                                document.createElement(
                                                                    "input"
                                                                );
                                                            hiddenField.type =
                                                                "hidden";
                                                            hiddenField.name = `${key}[${subKey}]`;
                                                            hiddenField.value =
                                                                payload[key][
                                                                    subKey
                                                                ];
                                                            form.appendChild(
                                                                hiddenField
                                                            );
                                                        }
                                                    }
                                                } else {
                                                    const hiddenField =
                                                        document.createElement(
                                                            "input"
                                                        );
                                                    hiddenField.type = "hidden";
                                                    hiddenField.name = key;
                                                    hiddenField.value =
                                                        payload[key];
                                                    form.appendChild(
                                                        hiddenField
                                                    );
                                                }
                                            }
                                        }
                                        document.body.appendChild(form);
                                        form.submit();
                                    } else {
                                        window.location.href =
                                            data[
                                                "payment_gateway_redirect_url"
                                            ];
                                    }
                                },
                                (error) => {
                                    this.disable_button = false;
                                    this.closeComponent(false, false);
                                    console.error(error);
                                }
                            );
                    }
                } else {
                    alert("Indent taken for this route you cannot order");
                    this.disable_button = false;
                    this.closeComponent(false, true);
                    return false;
                }
            },
            (error) => {
                console.error(error);
            }
        );
    }

    ngOnInit() {}

    closeComponent(data: any, alert) {
        this.dialogRef.close({
            is_order_placed: data,
            is_indent_closed: alert,
        });
    }

    closeDialog() {
        this.dialogRef.close({
            is_order_placed: false,
            is_indent_closed: false,
        });
    }
}
interface DialogData {
    agent_id: number;
    image_type: "";
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-by-product-order-register',
  templateUrl: './by-product-order-register.component.html',
  styleUrls: ['./by-product-order-register.component.scss']
})
export class ByProductOrderRegisterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';

@Component({
    selector: 'app-tender',
    templateUrl: './tender.component.html',
    styleUrls: ['./tender.component.scss']
})
export class TenderComponent implements OnInit {

    tender_list: any;
    tender_image_dict: any;
    is_data_available = false;

    constructor(private httpService: HttpService) {
        this.is_data_available = false;
        this.httpService.serveActiveTender().subscribe((data) => {
            this.tender_list = data['tender_list'];
            this.tender_image_dict = data['tender_image_dict'];
            this.is_data_available = true;
        }, (error) => {
            console.error(error);
            this.is_data_available = false;

        });
    }

    detectMimeType(base64_file) {
        let signatures = {
            JVBERi0: "application/pdf",
            R0lGODdh: "image/gif",
            iVBORw0KGgo: "image/png",
            '/9j/4AAQ': "image/jpeg"
        };
        for (var s in signatures) {
            if (base64_file.indexOf(s) === 0) {
                return signatures[s];
            }
        }
    }
    downloadClicked(base64_file, document_name) {
        let file_mime_type = this.detectMimeType(base64_file);
        const linkSource = `data:${file_mime_type};base64,${base64_file}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        let file_type = file_mime_type.split('/')[1]
        let filename = document_name + '.' + file_type;
        downloadLink.download = document_name + '.' + file_type;
        downloadLink.click();
    }

    ngOnInit() {
    }

}

import { Component, OnInit } from '@angular/core';
import { DataTransferService } from 'src/app/data-transfer.service';
import { HttpService } from 'src/app/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
    selector: 'app-manual',
    templateUrl: './manual.component.html',
    styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
    selected_manual: any;
    is_data_available: boolean = true;
    user_manual_data: any = null;
    page_header: string;

    constructor(private dataTransfer: DataTransferService, private httpService: HttpService, private domSanitizer: DomSanitizer, private authenticationService: AuthenticationService) {
        this.authenticationService.user_manual.subscribe((data) => {
            this.serveUserManual();
        });
        this.serveUserManual();
    }

    serveUserManual() {
        this.selected_manual = this.dataTransfer.seletected_manual;
        if (this.selected_manual === 'existing_customer') {
            this.page_header = 'Existing Customer';
        } else if (this.selected_manual === 'new_customer') {
            this.page_header = 'New Customer';
        } else {
            this.page_header = 'Booth Agent';
        }
        let data_dict = {
            'selected_manual': this.selected_manual,
        };
        this.is_data_available = false;
        this.httpService.getUserManual(data_dict).subscribe((data) => {
            this.user_manual_data = data;
            this.user_manual_data['pdf_data'] = this.domSanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + data['pdf_data']);
            this.is_data_available = true;
        }, (error) => {
            this.is_data_available = true;
            console.error(error);
        });
    }
    ngOnInit() {
    }

}

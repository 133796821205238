import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataTransferService {
    selected_option: any;
    album_details: any;
    seletected_manual: any;

    constructor() { }

    registerService(selected_option) {
        this.selected_option = selected_option;
    }

    albumSelected(album_obj) {
        this.album_details = album_obj;
    }

    userManual(manual) {
        this.seletected_manual = manual;
    }

}

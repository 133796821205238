import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
// import { GlobalProviderService } from '../global-provider.service';

const TOKEN_KEY = 'auth-token';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    authendicationState = new BehaviorSubject(false);
    login_event = new EventEmitter<any>();
    logout_event = new EventEmitter<any>();
    user_manual = new EventEmitter<any>();

    constructor(private globalProvider: GlobalService, private route: Router) { }

    // async login(user_form) {
    // this.httpClient.post(`${this.globalProvider.server_url}/main/app/login/for/token/`, user_form).subscribe((data) => {
    //   localStorage.setItem('TOKEN_KEY', JSON.stringify(data['token']));
    //   // localStorage.setItem('first_name', JSON.stringify(data['first_name']));
    //   // localStorage.setItem('user_profile', JSON.stringify(data['user_profile']));
    //   // localStorage.setItem('user_type', JSON.stringify(data['user_profile']['user_type']));
    //   this.authendicationState.next(true);
    //   this.login_event.emit(data['token']);
    //   this.http.setTokentoHeaders(data['token']);
    //   this.route.navigateByUrl('/');
    // }, (error) => {
    //   console.error(error);
    //   const error_message = error['detail'];
    //     alert(error_message);
    // });
    // }

    async logout() {
        this.authendicationState.next(false);
        this.logout_event.emit();
        this.route.navigateByUrl('');
        return localStorage.clear();
    }

    isAuthenticated() {
        return this.authendicationState.value;
    }

    async checkToken() {
        let token = JSON.parse(localStorage.getItem(TOKEN_KEY));
        if (token != null) {
            this.authendicationState.next(true);
        }
        return localStorage.getItem(TOKEN_KEY);
    }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layout/preloader/preloader.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ProductListComponent } from './components/pages/product-list/product-list.component';
import { LoginComponent } from './components/pages/login/login.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule, MatStepper, MatHorizontalStepper } from '@angular/material/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RegisterIntroComponent } from './components/pages/register/register-intro/register-intro.component';
import { CustomerRegisterComponent } from './components/pages/register/customer-register/customer-register.component';
import { AgentRequestFormComponent } from './components/pages/register/agent-request-form/agent-request-form.component';
import { AgmCoreModule } from '@agm/core';
import { IndivioulBoothDetailsComponent } from './components/pages/register/indivioul-booth-details/indivioul-booth-details.component';
import { TimerPipe } from './components/pages/register/customer-register/timer-pipe';
import { AgentOrderComponent } from './components/pages/agent/agent-order/agent-order.component';
import { AgentConfirmOrderComponent } from './components/pages/agent/agent-confirm-order/agent-confirm-order.component';
import { AgentOrderHistoryComponent } from './components/pages/agent/agent-order-history/agent-order-history.component';
import { IcustomerOrderComponent } from './components/pages/icustomer/icustomer-order/icustomer-order.component';
import { IndivioulBoothSelectionComponent } from './components/pages/icustomer/indivioul-booth-selection/indivioul-booth-selection.component';
import { BoothChangeForOrderComponent } from './components/pages/icustomer/booth-change-for-order/booth-change-for-order.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { GalleryInDetailsComponent } from './components/pages/gallery-in-details/gallery-in-details.component';
import { MatProgressSpinnerModule, MatExpansionModule, MatCheckboxModule, MatSnackBarModule, MatAutocompleteModule, MatListModule } from '@angular/material';
import { IndivioulPhotoComponent } from './components/pages/indivioul-photo/indivioul-photo.component';
import { CarrierComponent } from './components/pages/carrier/carrier.component';
import { TenderComponent } from './components/pages/tender/tender.component';
import { AgentOrderEditComponent } from './components/pages/agent/agent-order-edit/agent-order-edit.component';
import { PaymentCheckPageComponent } from './components/pages/payment-check-page/payment-check-page.component';
import { MobileNumberVerificationComponent } from './components/pages/mobile-number-verification/mobile-number-verification.component';
import { PasswordChangeOptionComponent } from './component/pages/password-change-option/password-change-option.component';
import { IcustomerPasswordGenerationComponent } from './components/pages/icustomer-password-generation/icustomer-password-generation.component';
import { IcustomerAadharVerificationComponent } from './components/pages/icustomer/icustomer-aadhar-verification/icustomer-aadhar-verification.component';
import { ManualComponent } from './components/pages/manual/manual.component';
import { OrderCategorySelectionComponent } from './by_products/order-category-selection/order-category-selection.component';
import { TimeMismatchAlertComponent } from './app/component/time-mismatch-alert/time-mismatch-alert.component';
import { ForgotPasswordComponent } from './app/component/forgot-password/forgot-password.component';
import { ByProductOrderRegisterComponent } from './by_products/by-product-order-register/by-product-order-register.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        AboutComponent,
        TeamComponent,
        ServicesComponent,
        ServiceDetailsComponent,
        NotFoundComponent,
        ComingSoonComponent,
        FaqComponent,
        BlogComponent,
        BlogDetailsComponent,
        ProjectsComponent,
        ProjectDetailsComponent,
        ContactComponent,
        ProductListComponent,
        LoginComponent,
        RegisterIntroComponent,
        CustomerRegisterComponent,
        AgentRequestFormComponent,
        IndivioulBoothDetailsComponent,
        TimerPipe,
        AgentOrderComponent,
        AgentConfirmOrderComponent,
        AgentOrderHistoryComponent,
        IcustomerOrderComponent,
        IndivioulBoothSelectionComponent,
        BoothChangeForOrderComponent,
        GalleryComponent,
        GalleryInDetailsComponent,
        IndivioulPhotoComponent,
        CarrierComponent,
        TenderComponent,
        AgentOrderEditComponent,
        PaymentCheckPageComponent,
        MobileNumberVerificationComponent,
        PasswordChangeOptionComponent,
        IcustomerPasswordGenerationComponent,
        IcustomerAadharVerificationComponent,
        ManualComponent,
        OrderCategorySelectionComponent,
        TimeMismatchAlertComponent,
        ForgotPasswordComponent,
        ByProductOrderRegisterComponent,
        PrivacyPolicyComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatCardModule,
        ReactiveFormsModule,
        FormsModule,
        MatStepperModule,
        HttpClientModule,
        MatIconModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBAG_QnJjbKczCVI093hYwMDS14b8DGEcI'
        }),
        MatButtonModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatListModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
    entryComponents: [LoginComponent,
        IndivioulBoothDetailsComponent,
        AgentConfirmOrderComponent,
        IndivioulBoothSelectionComponent,
        BoothChangeForOrderComponent,
        IndivioulPhotoComponent,
        AgentOrderEditComponent,
        PasswordChangeOptionComponent,
        IcustomerAadharVerificationComponent,
        OrderCategorySelectionComponent,
        TimeMismatchAlertComponent,
        ForgotPasswordComponent,
        ByProductOrderRegisterComponent,
    ]
})
export class AppModule { }

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { catchError } from "rxjs/operators";
import { GlobalService } from "src/app/global.service";
import { HttpService } from "src/app/http.service";

@Component({
    selector: "app-agent-order-edit",
    templateUrl: "./agent-order-edit.component.html",
    styleUrls: ["./agent-order-edit.component.scss"],
})
export class AgentOrderEditComponent implements OnInit {
    selected_date: any;
    selected_business_code: any;
    product_list: any;
    session_list: any;
    product_form_dict: any = {};
    is_data_available = false;
    amount_before_edit = 1;
    pay_amount = 0;
    customer_wallet = 10; // initial wallet
    customer_wallet_after_edit: any; // after edit the order
    enable_wallet_checkbox = false;
    final_customer_wallet = 0; // final customer wallet price
    is_wallet_enabled = true;
    product_availability: any;
    enable_minimum_warning_for_loose_milk: boolean = false;
    product_groupby_list: any;
    business_type_id: any;
    product_quantity_variation_list: any;
    disable_button = false;
    business_details: any = null;
    route_indent_prepate_status_per_session = { 1: false, 2: false };
    employee_user_type_id: any;
    is_minimum_product_is_available = false;
    product_wise_minumum = {};
    minimum_quantity_for_product: any;
    ordered_booth_code_with_count = {};
    is_overall_limit_reached = true;
    overall_litre_limit: any;
    business_wise_maximum = [];
    milk_litre_limit: any = 0;
    max_milk_litre_limit: any = 0;
    milk_product_list = [];
    is_min_milk_litre_limit_reached = true;
    is_max_milk_litre_limit_reached = false;
    allowance_config: any = null;
    allowance_extra_quantity: number;
    extra_milk: number = 0;
    mandatory: boolean = false;
    current_total_milk_litre = 0;
    is_wallet_modified: boolean;
    milk_allocation: any;
    paymentGateways: any[] = null;
    selectedGateway: any = null;

    constructor(
        public dialogRef: MatDialogRef<AgentOrderEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private httpService: HttpService,
        private global: GlobalService
    ) {
        this.selected_date = data["selected_date"];
        this.selected_business_code = data["selected_business_code"];
        this.business_type_id = data["business_type_id"];
        let data_dict = {
            selected_date: this.selected_date,
            from: "website",
            business_code: this.selected_business_code,
        };
        this.httpService.checkEditOrderPermission(data_dict).subscribe(
            (data) => {
                if (!data["permission_available"]) {
                    alert(
                        "You cannot edit this order. This order placed by " +
                            data["ordered_person_firstname"]
                    );
                    this.dialogRef.close();
                    return false;
                }
            },
            (error) => {
                console.error(error);
            }
        );
        this.serveSaleDetails();
        this.servePaymentGateways();
    }

    async serveSaleDetails() {
        let data_dict = {
            from: "website",
        };
        this.httpService.getProductList(data_dict).subscribe(
            (data) => {
                this.product_list = data["product_list"];
                this.session_list = data["session_list"];
                this.product_wise_minumum = {};
                data["product_list"].forEach((product_element) => {
                    this.product_wise_minumum[product_element.product_id] = {
                        is_reached: false,
                        value_in_litre: 0,
                    };
                });
                this.product_availability = data["product_availability"];
                this.product_groupby_list = data["product_groupby_list"];
                this.product_quantity_variation_list =
                    data["product_quantity_variation_list"];
                this.minimum_quantity_for_product =
                    data["minimum_quantity_for_product"];
                this.overall_litre_limit = data["overall_litre_limit"];
                this.milk_litre_limit = data["milk_litre_limit"];
                this.max_milk_litre_limit = data["max_milk_litre_limit"];
                this.allowance_config = data["allowance_config"];
                this.milk_product_list = data["milk_product_list"];
                let data_dict = {
                    selected_date: this.selected_date,
                    from: "website",
                };
                this.httpService.serveSingleSalegroup(data_dict).subscribe(
                    (sale_details) => {
                        let order_data = sale_details["order_details"];
                        this.business_details =
                            sale_details["business_details"];
                        this.customer_wallet =
                            sale_details["current_wallet_amount"];
                        this.customer_wallet_after_edit =
                            sale_details["current_wallet_amount"];
                        this.is_data_available = true;
                        this.product_form_dict["session"] = {};
                        this.amount_before_edit =
                            order_data["total_price_per_date"];
                        this.product_form_dict["total_price_per_date"] =
                            order_data["total_price_per_date"];
                        this.product_form_dict["total_diffrence"] = 0;
                        this.product_form_dict["pay_amount"] = 0;
                        this.product_form_dict["total_price_per_product"] = {};
                        this.product_form_dict["total_price_per_session"] = {};
                        data["product_list"]
                            .filter((x) => x.product_group_id == 1)
                            .forEach((product_element) => {
                                let prodid = product_element.product_id;
                                let tot1;
                                let tot2;
                                var sess1 = order_data.session[1];
                                var sess2 = order_data.session[2];
                                if (sess1 != undefined)
                                    tot1 = sess1.product[prodid];
                                if (sess2 != undefined)
                                    tot2 = sess2.product[prodid];
                                tot1 = tot1 == undefined ? 0 : tot1.quantity;
                                tot2 = tot2 == undefined ? 0 : tot2.quantity;
                                let totqty = tot1 + tot2;
                                this.business_wise_maximum.push({
                                    productid: product_element.product_id,
                                    short_name:
                                        product_element.product_short_name,
                                    is_reached:
                                        product_element.business_wise_product_max_quantity !==
                                            null &&
                                        totqty >
                                            product_element.business_wise_product_max_quantity,
                                    value_in_litre:
                                        totqty == undefined ? 0 : totqty,
                                    business_wise_max_lmt:
                                        product_element.business_wise_product_max_quantity,
                                });
                            });
                        data["session_list"].forEach((session_element) => {
                            this.product_form_dict["session"][
                                session_element.id
                            ] = {};
                            if (
                                order_data[
                                    "total_price_per_session"
                                ].hasOwnProperty(session_element.id)
                            ) {
                                this.product_form_dict[
                                    "total_price_per_session"
                                ][session_element.id] =
                                    order_data["total_price_per_session"][
                                        session_element.id
                                    ];
                            } else {
                                this.product_form_dict[
                                    "total_price_per_session"
                                ][session_element.id] = 0;
                            }
                            if (
                                order_data["session"].hasOwnProperty(
                                    session_element.id
                                )
                            ) {
                                this.product_form_dict["session"][
                                    session_element.id
                                ]["product"] = {};
                                this.product_form_dict["session"][
                                    session_element.id
                                ]["sale_group_id"] =
                                    order_data["session"][session_element.id][
                                        "sale_group_id"
                                    ];
                                data["product_list"].forEach(
                                    (product_element) => {
                                        if (
                                            order_data["session"][
                                                session_element.id
                                            ]["product"].hasOwnProperty(
                                                product_element.product_id
                                            )
                                        ) {
                                            this.product_form_dict["session"][
                                                session_element.id
                                            ]["product"][
                                                product_element.product_id
                                            ] =
                                                order_data["session"][
                                                    session_element.id
                                                ]["product"][
                                                    product_element.product_id
                                                ];
                                            if (
                                                order_data["session"][
                                                    session_element.id
                                                ]["product"][
                                                    product_element.product_id
                                                ]["quantity"] === 0
                                            ) {
                                                this.product_form_dict[
                                                    "session"
                                                ][session_element.id][
                                                    "product"
                                                ][product_element.product_id][
                                                    "quantity"
                                                ] = null;
                                            }
                                        } else {
                                            this.product_form_dict["session"][
                                                session_element.id
                                            ]["product"][
                                                product_element.product_id
                                            ] = {
                                                quantity: null,
                                                price: 0,
                                                sale_id: null,
                                                sale_group_id: null,
                                            };
                                        }
                                    }
                                );
                            } else {
                                this.product_form_dict["session"][
                                    session_element.id
                                ]["product"] = {};
                                this.product_form_dict["session"][
                                    session_element.id
                                ]["sale_group_id"] = null;
                                data["product_list"].forEach(
                                    (product_element) => {
                                        this.product_form_dict["session"][
                                            session_element.id
                                        ]["product"][
                                            product_element.product_id
                                        ] = {
                                            quantity: null,
                                            price: 0,
                                            sale_id: null,
                                        };
                                    }
                                );
                            }
                            data["product_list"].forEach((product_element) => {
                                if (
                                    order_data[
                                        "total_price_per_product"
                                    ].hasOwnProperty(product_element.product_id)
                                ) {
                                    this.product_form_dict[
                                        "total_price_per_product"
                                    ][product_element.product_id] =
                                        order_data["total_price_per_product"][
                                            product_element.product_id
                                        ];
                                } else {
                                    this.product_form_dict[
                                        "total_price_per_product"
                                    ][product_element.product_id] = 0;
                                }
                            });
                        });
                        this.getRouteIndentCloseStatus();
                        if (
                            this.minimum_quantity_for_product.hasOwnProperty(
                                this.business_type_id
                            )
                        ) {
                            data["product_list"].forEach((product_element) => {
                                let total_litre = 0;
                                data["session_list"].forEach(
                                    (session_element) => {
                                        total_litre +=
                                            (this.product_form_dict["session"][
                                                session_element.id
                                            ]["product"][
                                                product_element.product_id
                                            ]["quantity"] *
                                                product_element.product_quantity) /
                                            1000;
                                    }
                                );
                                this.product_wise_minumum[
                                    product_element.product_id
                                ]["value_in_litre"] = total_litre;
                                let minimum_quantity =
                                    this.minimum_quantity_for_product[
                                        this.business_type_id
                                    ][product_element.product_id];
                                if (total_litre < minimum_quantity) {
                                    this.product_wise_minumum[
                                        product_element.product_id
                                    ]["is_reached"] = false;
                                } else {
                                    this.product_wise_minumum[
                                        product_element.product_id
                                    ]["is_reached"] = true;
                                }
                            });
                            this.checkMinimumIsReached();
                        }
                        let total_milk_litre = 0;
                        this.milk_product_list.forEach((element) => {
                            this.session_list.forEach((session_element) => {
                                if (
                                    this.product_form_dict["session"][
                                        session_element.id
                                    ]["product"].hasOwnProperty(element.id)
                                ) {
                                    total_milk_litre +=
                                        (this.product_form_dict["session"][
                                            session_element.id
                                        ]["product"][element.id]["quantity"] *
                                            element.quantity) /
                                        1000;
                                }
                            });
                        });
                        this.current_total_milk_litre = total_milk_litre;
                        this.is_min_milk_litre_limit_reached = true;
                        let group_total_litre = 0;
                        if (this.allowance_config["status"]) {
                            group_total_litre = this.calculateGroupTotalLitre(
                                this.allowance_config["product_group_id"]
                            );
                            this.checkAllowance(group_total_litre);
                        }

                        if (total_milk_litre > 0) {
                            if (total_milk_litre >= this.milk_litre_limit) {
                                this.is_min_milk_litre_limit_reached = true;
                            } else {
                                this.is_min_milk_litre_limit_reached = false;
                            }
                            if (this.allowance_config["status"]) {
                                let is_mandatory_check =
                                    this.isAllowanceMandatoryLimit(
                                        group_total_litre
                                    );
                                if (this.max_milk_litre_limit != null) {
                                    if (
                                        total_milk_litre >
                                            this.max_milk_litre_limit +
                                                this.extra_milk ||
                                        is_mandatory_check
                                    ) {
                                        this.is_max_milk_litre_limit_reached =
                                            true;
                                    } else {
                                        this.is_max_milk_litre_limit_reached =
                                            false;
                                    }
                                }
                            }
                        }
                        if (
                            this.overall_litre_limit.hasOwnProperty(
                                this.business_type_id
                            )
                        ) {
                            let total_litre = 0;
                            data["product_list"].forEach((product_element) => {
                                data["session_list"].forEach(
                                    (session_element) => {
                                        total_litre +=
                                            (this.product_form_dict["session"][
                                                session_element.id
                                            ]["product"][
                                                product_element.product_id
                                            ]["quantity"] *
                                                product_element.product_quantity) /
                                            1000;
                                    }
                                );
                            });
                            let minimum_quantity =
                                this.overall_litre_limit[this.business_type_id];
                            if (total_litre < minimum_quantity) {
                                this.is_overall_limit_reached = false;
                            } else {
                                this.is_overall_limit_reached = true;
                            }
                        }
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            },
            (error) => {
                console.error(error);
            }
        );
    }

    checkMinimumIsReached() {
        this.is_minimum_product_is_available = false;
        this.product_list.forEach((product_element) => {
            if (
                this.product_wise_minumum[product_element.product_id][
                    "value_in_litre"
                ] !== 0
            ) {
                if (
                    !this.product_wise_minumum[product_element.product_id][
                        "is_reached"
                    ]
                ) {
                    this.is_minimum_product_is_available = true;
                }
            }
        });
    }

    getRouteIndentCloseStatus() {
        let data_dict = {
            selected_date: this.selected_date,
            selected_business_code: this.selected_business_code,
        };
        this.httpService
            .getRouteIndentStatusPerSessionForWebsite(data_dict)
            .subscribe(
                (data) => {
                    this.route_indent_prepate_status_per_session[1] = data[1];
                    this.route_indent_prepate_status_per_session[2] = data[2];
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    checkLooseMilkQuantityWarning(product_index) {
        this.enable_minimum_warning_for_loose_milk = false;
        this.product_groupby_list[3].forEach((loose_milk_id) => {
            this.session_list.forEach((session_element) => {
                let value =
                    this.product_form_dict["session"][session_element.id][
                        "product"
                    ][loose_milk_id]["quantity"];
                if (value !== null && value !== 0) {
                    if (
                        value <
                        this.product_list[product_index]["minimum_quantity"]
                    ) {
                        this.enable_minimum_warning_for_loose_milk = true;
                    }
                }
            });
        });
    }

    productQuantityChanged(
        session_id,
        product_id,
        product_price,
        product_index,
        product_quantity
    ) {
        this.customer_wallet_after_edit = this.customer_wallet;
        console.info("init", this.customer_wallet_after_edit);
        this.is_wallet_modified = false; // Initialize flag as false

        let temp_quantity =
            this.product_form_dict["session"][session_id]["product"][
                product_id
            ]["quantity"];
        if (temp_quantity !== "") {
            this.product_form_dict["session"][session_id]["product"][
                product_id
            ]["quantity"] = parseInt(
                this.product_form_dict["session"][session_id]["product"][
                    product_id
                ]["quantity"]
            );
        } else {
            this.product_form_dict["session"][session_id]["product"][
                product_id
            ]["quantity"] = null;
        }

        let quantity =
            this.product_form_dict["session"][session_id]["product"][
                product_id
            ]["quantity"];
        if (this.product_list[product_index]["product_group_id"] === 3) {
            this.checkLooseMilkQuantityWarning(product_index);
            this.product_form_dict["session"][session_id]["product"][
                product_id
            ]["price"] = 0;
            this.product_quantity_variation_list[product_id].forEach(
                (element) => {
                    if (
                        quantity >= element["min_quantity"] &&
                        quantity <= element["max_quantity"]
                    ) {
                        this.product_form_dict["session"][session_id][
                            "product"
                        ][product_id]["price"] = quantity * element["mrp"];
                    }
                }
            );
        } else {
            this.product_form_dict["session"][session_id]["product"][
                product_id
            ]["price"] = quantity * product_price;
        }

        let total_litre = 0;
        let total_cost = 0;
        this.session_list.forEach((session_element) => {
            total_cost +=
                this.product_form_dict["session"][session_element.id][
                    "product"
                ][product_id]["price"];
            total_litre +=
                (this.product_form_dict["session"][session_element.id][
                    "product"
                ][product_id]["quantity"] *
                    product_quantity) /
                1000;
        });
        this.product_wise_minumum[product_id]["value_in_litre"] = total_litre;

        if (
            this.minimum_quantity_for_product.hasOwnProperty(
                this.business_type_id
            )
        ) {
            let minimum_quantity =
                this.minimum_quantity_for_product[this.business_type_id][
                    product_id
                ];
            this.product_wise_minumum[product_id]["is_reached"] =
                total_litre >= minimum_quantity;
            this.checkMinimumIsReached();
        }

        if (this.product_list[product_index]["product_group_id"] === 1) {
            let temp_session =
                session_id == 1 ? session_id + 1 : session_id - 1;
            let totquantity =
                this.product_form_dict["session"][temp_session]["product"][
                    product_id
                ]["quantity"] + quantity;

            if (
                totquantity >
                    this.product_list[product_index][
                        "business_wise_product_max_quantity"
                    ] &&
                !(
                    this.product_list[product_index][
                        "business_wise_product_max_quantity"
                    ] == null
                )
            ) {
                this.business_wise_maximum[product_index]["is_reached"] = true;
                this.business_wise_maximum[product_index]["value_in_litre"] =
                    totquantity;
            } else {
                this.business_wise_maximum[product_index]["is_reached"] = false;
                this.business_wise_maximum[product_index]["value_in_litre"] =
                    totquantity;
            }
        }

        if (this.overall_litre_limit.hasOwnProperty(this.business_type_id)) {
            total_litre = 0;
            this.product_list.forEach((product_element) => {
                this.session_list.forEach((session_element) => {
                    total_litre +=
                        (this.product_form_dict["session"][session_element.id][
                            "product"
                        ][product_element.product_id]["quantity"] *
                            product_element.product_quantity) /
                        1000;
                });
            });
            this.is_overall_limit_reached =
                total_litre >= this.overall_litre_limit[this.business_type_id];
        }

        this.product_form_dict["total_price_per_product"][product_id] =
            total_cost;

        let total_cost_per_session = 0;
        this.product_list.forEach((product_element) => {
            total_cost_per_session +=
                this.product_form_dict["session"][session_id]["product"][
                    product_element.product_id
                ]["price"];
        });
        this.product_form_dict["total_price_per_session"][session_id] =
            total_cost_per_session;

        this.product_form_dict["total_price_per_date"] = 0;
        this.product_list.forEach((product_element) => {
            this.product_form_dict["total_price_per_date"] +=
                this.product_form_dict["total_price_per_product"][
                    product_element.product_id
                ];
        });

        this.product_form_dict["pay_amount"] = 0;
        this.product_form_dict["total_diffrence"] = 0;

        if (
            this.product_form_dict["total_price_per_date"] >
            this.amount_before_edit
        ) {
            this.is_wallet_modified = true;
            this.product_form_dict["total_diffrence"] =
                this.product_form_dict["total_price_per_date"] -
                this.amount_before_edit;
            this.enable_wallet_checkbox = true;

            if (this.is_wallet_enabled) {
                this.product_form_dict["pay_amount"] =
                    this.product_form_dict["total_diffrence"] -
                    this.customer_wallet_after_edit;
                if (this.product_form_dict["pay_amount"] < 0) {
                    this.product_form_dict["pay_amount"] = 0;
                }
            } else {
                this.product_form_dict["pay_amount"] =
                    this.product_form_dict["total_diffrence"];
            }

            if (this.is_wallet_enabled) {
                this.final_customer_wallet =
                    this.customer_wallet -
                    this.product_form_dict["total_diffrence"];
                console.info("isenabled", this.final_customer_wallet);
                if (this.final_customer_wallet < 0) {
                    this.final_customer_wallet = 0;
                    console.info("isenabledif", this.final_customer_wallet);
                }
            } else {
                this.final_customer_wallet = this.customer_wallet;
                console.info("isenabledelse", this.final_customer_wallet);
            }
        } else if (
            this.product_form_dict["total_price_per_date"] <
            this.amount_before_edit
        ) {
            this.is_wallet_modified = true;
            this.enable_wallet_checkbox = false;
            this.customer_wallet_after_edit +=
                this.amount_before_edit -
                this.product_form_dict["total_price_per_date"];
            this.final_customer_wallet = this.customer_wallet_after_edit;
            console.info(
                "isenabledtotal_price_per_date",
                this.final_customer_wallet
            );
        } else {
            this.enable_wallet_checkbox = false;
            this.customer_wallet_after_edit = this.customer_wallet;
            this.product_form_dict["total_diffrence"] = 0;
            this.final_customer_wallet = this.customer_wallet;
            console.info(
                "isenabledtotal_diffrence",
                this.final_customer_wallet
            );
        }

        // Calculate milk and allowances if applicable
        this.product_list.forEach((product) => {
            if (product.product_group_id === 1) {
                // Milk products
                this.milk_allocation[product.product_id] = total_litre;
            } else if (product.product_group_id === 2) {
                // Allowances like curd
                let curd_quantity = 0;
                this.session_list.forEach((session) => {
                    curd_quantity +=
                        this.product_form_dict["session"][session.id][
                            "product"
                        ][product.product_id]["quantity"] || 0;
                });
                this.allowance_config[product.product_id] = curd_quantity;
            }
        });
    }

    isAllowanceMandatoryLimit(group_total_litre) {
        if (!this.allowance_config["status"]) {
            return false;
        }
        if (this.allowance_config["mandatory_limit"] == 0) {
            return false;
        }
        if (
            this.current_total_milk_litre >=
            this.allowance_config["mandatory_limit"]
        ) {
            this.mandatory = true;
            if (
                group_total_litre < this.allowance_config["product_base_limit"]
            ) {
                return true;
            } else {
                this.mandatory = false;
                return false;
            }
        } else {
            this.mandatory = false;
            return false;
        }
    }

    checkAllowance(group_total_litre) {
        if (this.allowance_config["status"]) {
            this.allowance_extra_quantity =
                group_total_litre - this.allowance_config["product_base_limit"];
            if (
                this.allowance_extra_quantity >=
                this.allowance_config["allowance_min_quantity"]
            ) {
                let base_limit_count = Math.trunc(
                    this.allowance_extra_quantity /
                        this.allowance_config["allowance_min_quantity"]
                );
                this.extra_milk =
                    base_limit_count *
                    this.allowance_config["allowance_min_quantity"] *
                    this.allowance_config["allowance_multiplier"];
            } else {
                this.extra_milk = 0;
                this.allowance_extra_quantity = 0;
            }
        }
    }

    calculateGroupTotalLitre(product_group_id) {
        let group_total_litre = 0;
        let product_groupby_list_length =
            this.product_groupby_list[product_group_id].length;
        if (product_groupby_list_length == 0) {
            return 0;
        }
        this.product_groupby_list[product_group_id].forEach((prod_id) => {
            this.session_list.forEach((session_element) => {
                let product_quantity = this.product_list.find(
                    (x) => x.product_id == prod_id
                ).product_quantity;
                let prod_litre =
                    (this.product_form_dict["session"][session_element.id][
                        "product"
                    ][prod_id]["quantity"] *
                        product_quantity) /
                    1000;
                group_total_litre += prod_litre;
            });
        });

        return group_total_litre;
    }

    checkConfirmButtonValid() {
        if (
            this.enable_minimum_warning_for_loose_milk ||
            this.disable_button ||
            !this.is_overall_limit_reached ||
            this.is_minimum_product_is_available ||
            !this.is_min_milk_litre_limit_reached ||
            this.is_max_milk_litre_limit_reached ||
            (this.product_form_dict["pay_amount"] > 0 && !this.selectedGateway)
        ) {
            return true;
        } else {
            return false;
        }
    }

    walletCheckboxChanged(event) {
        if (!event.checked) {
            this.is_wallet_enabled = false;
            this.product_form_dict["pay_amount"] =
                this.product_form_dict["total_diffrence"];

            this.final_customer_wallet = this.customer_wallet;
            console.info("walltetamount", this.final_customer_wallet);
        } else {
            this.is_wallet_enabled = true;
            this.product_form_dict["pay_amount"] -=
                this.customer_wallet_after_edit;
            if (this.product_form_dict["pay_amount"] < 0) {
                this.product_form_dict["pay_amount"] = 0;
            }
            console.info(
                "walltetamountafteredit",
                this.customer_wallet_after_edit
            );

            // this.final_customer_wallet = 0;
        }
    }

    checkDisableForDeleteButton() {
        if (this.route_indent_prepate_status_per_session[1]) {
            return false;
        } else {
            return true;
        }
    }

    checkExpiry(session, product) {
        let order_date_in_format = new Date(this.selected_date);
        let expiry_date: any = new Date(
            order_date_in_format.setDate(
                order_date_in_format.getDate() - session.expiry_day_before
            )
        );
        expiry_date = expiry_date.toISOString().split("T")[0];
        let expiry_date_and_time: any = new Date(
            expiry_date + " " + session.expiry_time
        );
        if (this.route_indent_prepate_status_per_session[session.id]) {
            return true;
        } else if (
            this.product_availability[session.id][product.product_id] === false
        ) {
            return true;
        } else if (new Date() > expiry_date_and_time) {
            return true;
        } else {
            return false;
        }
    }

    async updateClicked() {
        console.log("bbb");
        let max_litre = this.business_wise_maximum.filter(
            (x) => x.is_reached == true
        );
        if (max_litre.length > 0) {
            let error_message = "";
            for (let msg of max_litre) {
                error_message +=
                    "Maximum limit has been reached for " +
                    msg.short_name +
                    "\n";
            }
            alert(error_message);
            return false;
        }
        if (this.mandatory) {
            let error_message =
                "Mandatory Curd " +
                this.allowance_config["product_base_limit"] +
                "L for Order above " +
                this.allowance_config["mandatory_limit"];
            alert(error_message);
            return false;
        }
        let data_dict = {
            product_form_details: this.product_form_dict,
            selected_date: this.selected_date,
            final_customer_wallet: this.final_customer_wallet,
            final_price: this.product_form_dict["pay_amount"],
            business_code: this.selected_business_code,
            extra_milk_qty: this.extra_milk == null ? 0 : this.extra_milk,
            extra_curd_qty: this.allowance_extra_quantity,
            from: "website",
        };
        this.disable_button = true;

        this.httpService
            .updateSelectedSaleGroup(data_dict)
            .pipe(
                catchError((error) => {
                    if (error.status === 400) {
                        console.error("Bad Request", error);
                        alert(error["error"]["message"]);
                        this.closeDialog(true);
                    }
                    // Return an empty observable to keep the stream alive
                    throw error;
                })
            )
            .subscribe(
                (data) => {
                    this.disable_button = false;
                    this.closeDialog(true);
                },
                (error) => {
                    this.closeDialog(false);
                    this.disable_button = false;
                    console.error(error);
                }
            );
    }

    servePaymentGateways() {
        const dcmpu_id = "b8a6803b-edfb-41cf-b2fd-cabcf67a80ff";
        this.httpService.servePaymentGatewayList(dcmpu_id).subscribe((data) => {
            this.paymentGateways = data["data"];
        });
    }

    selectPaymentGateway(gateway) {
        if (!gateway.is_active) {
            return;
        }
        this.selectedGateway = gateway.id;
    }

    async updateAndPayClicked() {
        console.log("click");
        let max_litre = this.business_wise_maximum.filter(
            (x) => x.is_reached == true
        );
        if (max_litre.length > 0) {
            let error_message = "";
            for (let msg of max_litre) {
                error_message +=
                    "Maximum limit has been reached for " +
                    msg.short_name +
                    "\n";
            }
            alert(error_message);
            return false;
        }
        if (this.mandatory) {
            let error_message =
                "Mandatory Curd " +
                this.allowance_config["product_base_limit"] +
                "L for Order above " +
                this.allowance_config["mandatory_limit"];
            alert(error_message);
            return false;
        }

        // Decide which wallet amount to send based on `is_wallet_modified`
        let wallet_to_send = this.is_wallet_modified
            ? this.final_customer_wallet
            : this.customer_wallet_after_edit;

        let data_dict = {
            product_form_details: this.product_form_dict,
            selected_date: this.selected_date,
            final_customer_wallet: wallet_to_send,
            final_price: this.product_form_dict["pay_amount"],
            business_code: this.selected_business_code,
            extra_milk_qty: this.extra_milk,
            extra_curd_qty: this.allowance_extra_quantity,
            from: "website",
            payment_gateway_id: this.selectedGateway,
        };

        if (this.product_form_dict["pay_amount"] === 0) {
            this.disable_button = true;
            this.httpService
                .updateSelectedSaleGroup(data_dict)
                .pipe(
                    catchError((error) => {
                        if (error.status === 400) {
                            console.error("Bad Request", error);
                            alert(error["error"]["message"]);
                            this.closeDialog(true);
                        }
                        // Return an empty observable to keep the stream alive
                        throw error;
                    })
                )
                .subscribe(
                    (data) => {
                        this.disable_button = false;
                        this.closeDialog(true);
                    },
                    (error) => {
                        this.closeDialog(false);
                        this.disable_button = false;
                        console.error(error);
                    }
                );
        } else if (this.product_form_dict["pay_amount"] > 0) {
            this.disable_button = true;
            this.httpService
                .updateAndPaySelectedSaleGroup(data_dict)
                .pipe(
                    catchError((error) => {
                        if (error.status === 400) {
                            console.error("Bad Request", error);
                            alert(error["error"]["message"]);
                            this.disable_button = false;
                            this.closeDialog(true);
                        }
                        // Return an empty observable to keep the stream alive
                        throw error;
                    })
                )
                .subscribe((data) => {
                    if (data["method"] === "POST") {
                        const form = document.createElement("form");
                        form.method = "POST";
                        form.action = data["payment_gateway_redirect_url"];
                        const payload = data["payload"];
                        for (const key in payload) {
                            if (payload.hasOwnProperty(key)) {
                                if (typeof payload[key] === "object") {
                                    for (const subKey in payload[key]) {
                                        if (
                                            payload[key].hasOwnProperty(subKey)
                                        ) {
                                            const hiddenField =
                                                document.createElement("input");
                                            hiddenField.type = "hidden";
                                            hiddenField.name = `${key}[${subKey}]`;
                                            hiddenField.value =
                                                payload[key][subKey];
                                            form.appendChild(hiddenField);
                                        }
                                    }
                                } else {
                                    const hiddenField =
                                        document.createElement("input");
                                    hiddenField.type = "hidden";
                                    hiddenField.name = key;
                                    hiddenField.value = payload[key];
                                    form.appendChild(hiddenField);
                                }
                            }
                        }
                        document.body.appendChild(form);
                        form.submit();
                    } else {
                        window.location.href =
                            data["payment_gateway_redirect_url"];
                    }
                });
        }
    }

    deleteOrderIsCliked() {
        if (confirm("இந்த ஆர்டரை கண்டிப்பாக நீக்க வேண்டுமா?")) {
            let data_dict = {
                selected_date: this.selected_date,
            };
            this.httpService.deleteSelectedOrder(data_dict).subscribe(
                (data) => {
                    this.global.showSnakeBar("Order has been removed");
                    this.closeDialog(true);
                },
                (error) => {
                    console.error(error);
                    this.global.showSnakeBar("Error while deteting this order");
                }
            );
        }
    }
    closeDialog(data) {
        this.dialogRef.close(data);
    }

    ngOnInit() {}
}

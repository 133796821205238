import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { Router } from '@angular/router';
import { DataTransferService } from 'src/app/data-transfer.service';

@Component({
    selector: 'app-register-intro',
    templateUrl: './register-intro.component.html',
    styleUrls: ['./register-intro.component.scss']
})
export class RegisterIntroComponent implements OnInit {
    business_types: any;

    constructor(private httpService: HttpService, private router: Router, private dataTransfer: DataTransferService) {
        this.httpService.serveBusinessTypes().subscribe((data) => {
            this.business_types = data['business_type'];
        }, (error) => {
            console.error(error);
        });
    }

    customerRegisterClicked() {
        this.router.navigateByUrl('/customer/register');
    }

    agentRegisterClicked(business_type) {
        this.dataTransfer.registerService(business_type);
        this.router.navigateByUrl('/agent/request/form');
    }
    ngOnInit() {
    }

}

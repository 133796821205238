import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/http.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    entered_user_name = null;
    enable_otp_section = false;
    entered_otp = null;
    user_id: any;
    mobile: any;
    otp_session: boolean = false;
    password_reset_session: boolean = false;
    new_pass_type: any = 'password';
    confirm_pass_type: any = 'password';
    confirm_pass: any;
    new_pass: any;
    alert: any;

    constructor(private httpService: HttpService, private router: Router) { }

    proceedCalled() {
        let data_dict = {
            'user_name': this.entered_user_name
        };
        this.httpService.usernameVerfication(data_dict).subscribe((data) => {
            if (data === 'user does not exist') {
                this.entered_user_name = null;
                alert('incorrect username!');
            } else {
                this.user_id = data['user_id'];
                this.mobile = data['mobile'];
                this.enable_otp_section = true;
            }
        }, (error) => {
            console.error(error);
            let error_messge = error.error;
            this.entered_user_name = null;
            alert(error_messge.message);
        });
    }

    confirmWithOtpClicked() {
        let data_dict = {
            'otp': this.entered_otp,
            'user_id': this.user_id
        };
        this.httpService.otpValidation(data_dict).subscribe((data) => {
            this.otp_session = false;
            this.password_reset_session = true;
        }, (error) => {
            console.error(error);
            this.entered_otp = null;
            alert(error.error.message);
        });
    }

    confirmPassword() {
        if (this.confirm_pass !== this.new_pass) {
            this.alert = '*passwords does not match';
        } else {
            this.alert = '';
        }
        if (this.confirm_pass === '') {
            this.alert = '';
        }
    }

    showNewPassword() {
        if (this.new_pass_type === 'password') {
            this.new_pass_type = 'text';
        } else {
            this.new_pass_type = 'password';
        }
    }

    showConfirmPassword() {
        if (this.confirm_pass_type === 'password') {
            this.confirm_pass_type = 'text';
        } else {
            this.confirm_pass_type = 'password';
        }
    }

    savePassword() {
        if (this.new_pass === this.confirm_pass) {
            let data_dict = {
                'user_id': this.user_id,
                'raw_password': this.new_pass,
            };
            this.httpService.resetPassword(data_dict).subscribe((data) => {
                this.router.navigateByUrl('');
                alert('Password changed successfully');
            }, (error) => {
                console.error(error);
            });
        }
    }

    ngOnInit() {
    }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { IndivioulBoothSelectionComponent } from '../indivioul-booth-selection/indivioul-booth-selection.component';
import { HttpService } from 'src/app/http.service';

@Component({
    selector: 'app-booth-change-for-order',
    templateUrl: './booth-change-for-order.component.html',
    styleUrls: ['./booth-change-for-order.component.scss']
})
export class BoothChangeForOrderComponent implements OnInit {
    pincode: any;
    booth_list: any;
    booth_lat_lng_data: any;
    user_profile_id: any;
    lat: any;
    lng: any;
    zoom: number = 15;
    page_action: any;
    selected_date: any;
    business_list: any;
    filtered_business_list: any;
    search_term: any = null;
    selected_business_id: any = null;
    customer_code: any = null;

    constructor(private dialogRef: MatDialogRef<BoothChangeForOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private httpService: HttpService, private dialog: MatDialog) {
        this.pincode = data['pincode'];
        this.user_profile_id = data['user_profile_id'];
        this.page_action = data['page_action'];
        this.selected_date = data['selected_date'];
        this.serveBoothList();
        this.httpService.getAllbusinessForBothChange().subscribe((data: []) => {
            this.business_list = data;
        }, (error) => {
            console.error(error);
        });
    }

    searchBusinessCalled(search_term) {
        this.selected_business_id = null;
        if (this.search_term == null || this.search_term === '') {
            this.filtered_business_list = [];
        } else {
            this.filtered_business_list = this.business_list.filter((element) => {
                return element.name.toLowerCase().indexOf(search_term.toLowerCase()) > -1 || element.code.toLowerCase().indexOf(search_term.toLowerCase()) > -1;
            });
        }
    }

    boothSelectedFromList(business_id) {
        this.selected_business_id = business_id;
    }

    // serving booth list under the pincode
    serveBoothList() {
        let data_dict = {
            'pincode': this.pincode
        };
        this.httpService.serveBoothListUnderPincode(data_dict).subscribe((data) => {
            this.booth_list = data;
            this.serveBoothLatLng();
        }, (error) => {
            console.error(error);
        });
    }

    async serveBoothLatLng() {
        this.httpService.serveBoothLatLng().subscribe((data) => {
            if (this.booth_list.length !== 0) {
                if (this.booth_list[0]['latitude'] !== null) {
                    this.lat = this.booth_list[0]['latitude'];
                    this.lng = this.booth_list[0]['longitude'];
                } else {
                    this.lat = 11.010778;
                    this.lng = 76.954279;
                }
            } else {
                this.lat = 11.010778;
                this.lng = 76.954279;
            }
            this.booth_lat_lng_data = data;
        }, (error) => {
            console.error(error);
        });
    }

    async confirmCicked(business_id) {

        let data_dict = {
            'user_profile_id': this.user_profile_id,
            'page_action': this.page_action,
            'business_id': business_id,
            'from': 'portal'
        };
        if (this.page_action === 'after_order') {
            data_dict['selected_date'] = this.selected_date;
        }
        if (confirm('Are you sure to confirm ?')) {
            this.httpService.linkBoothWithCustomer(data_dict).subscribe((data) => {
                this.dialogRef.close(true);
            }, (error) => {
                console.error(error);
            });
        }
    }

    onBoothSelect(business_data) {
        const dialogRef = this.dialog.open(IndivioulBoothSelectionComponent, {
            // panelClass: 'modal-class',
            data: {
                'business_id': business_data['business_id'],
                'user_profile_id': this.user_profile_id,
                'page_action': this.page_action,
                'selected_date': this.selected_date
            }
        });
        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.dialogRef.close(true);
            }
        });
    }

    closeClicked() {
        this.dialogRef.close(false);
    }

    ngOnInit() {
    }

}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../../pages/login/login.component';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})

export class HeaderComponent implements OnInit {

    location: any;
    is_logged = false;
    user_name = null;
    user_type_id: any = null;

    constructor(private router: Router, private dialog: MatDialog, location: Location, private authenticationService: AuthenticationService) {
        this.authenticationService.login_event.subscribe((data) => {
            this.is_logged = true;
            this.user_name = JSON.parse(localStorage.getItem('first_name'));
            this.user_type_id = JSON.parse(localStorage.getItem('user_type_id'));
        });
        this.authenticationService.logout_event.subscribe((data) => {
            this.is_logged = false;
            this.user_name = null;
        });
        const token = localStorage.getItem('TOKEN_KEY');
        if (token !== null) {
            // this.authenticationService.authendicationState.next(true);
            // this.authenticationService.login_event.emit(token);
            this.is_logged = true;
            setTimeout(() => {
                this.user_name = JSON.parse(localStorage.getItem('first_name'));
                this.user_type_id = JSON.parse(localStorage.getItem('user_type_id'));
            }, 500);

        }
    }

    loginPageClicked() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'login-modal-class';
        this.dialog.open(LoginComponent, dialogConfig);
    }

    ngOnInit() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                }
            });
    }

    routePage(page) {
        this.router.navigateByUrl(page);
    }

    logout() {
        this.authenticationService.logout();
    }
}

import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { AgentConfirmOrderComponent } from '../agent-confirm-order/agent-confirm-order.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgentOrderEditComponent } from '../agent-order-edit/agent-order-edit.component';
import { PasswordChangeOptionComponent } from 'src/app/component/pages/password-change-option/password-change-option.component';
import { ProgressSpinnerMode, ThemePalette } from '@angular/material';

@Component({
    selector: 'app-agent-order',
    templateUrl: './agent-order.component.html',
    styleUrls: ['./agent-order.component.scss']
})
export class AgentOrderComponent implements OnInit {
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    month_list_for_last_12_months: any = [];
    selected_month: any;
    product_list: any;
    session_list: any;
    today_date_time: any;
    today_date: any;
    product_form_dict: any = {};
    final_price: any = 0;
    order_date_list: any[] = [];
    future_order_date_list: any[] = [];
    selected_index: any = 0;
    form_start_date = null;
    duplicate_order_date_list: any;
    customer_wallet: any;
    customer_wallet_after_edit: any;
    tomorrow: any;
    is_wallet_selected = true;
    final_customer_wallet: any; // final customer wallet price
    final_price_before_wallet = 0;
    product_availability: any;
    business_type_id: any;
    products: any = [];
    business_confirmed = false;
    business_details = {};
    date = null;
    selected_business_code: any = null;
    is_business_selected = false;
    final_price_after_wallet: number;
    wallet_balance: number;
    order_details: any;
    product_groupby_list: any;
    enable_minimum_warning_for_loose_milk: boolean = false;
    display_confirmed_bill: boolean = false;
    booth_details: any = null;
    is_counter_selected = false;
    counter_list: any = null;
    sale_group_data: any;
    login_users: any;
    counter_name: any;
    one_week_order_details: any;
    product_quantity_variation_list: any;
    collection_center: any = [];
    logged_user_befere_select_counter: any;
    total_amount_in_counter: any = 0;
    total_booth_count: any;
    curd_product_index: any;
    enable_loose_milk_card = true;
    is_date_editable = false;
    route_indent_prepate_status_per_session = { 1: false, 2: false };
    is_minimum_product_is_available = false;
    product_wise_minumum = {};
    business_wise_maximum = [];
    minimum_quantity_for_product: any;
    is_overall_limit_reached = true;
    overall_litre_limit: any;

    // commission report variables
    milk_product_list: any;
    fermented_product_list: any;
    milk_product_report: any;
    fermented_product_report: any;
    milk_product_date_list: any;
    fermented_product_date_list: any;
    milk_product_total_price: any;
    fermented_product_total_price: any;
    overall_month_report: any;
    overall_month_total_price: any;
    global_config_dict: any;
    commission_details: any;
    is_data_available = true;
    color: ThemePalette = 'primary';
    mode: ProgressSpinnerMode = 'indeterminate';
    is_session_wise_edit = { 1: false, 2: false };
    product_wise_minimum_quantity: any;
    is_product_wise_minimum_check_is_valid = true;
    business_wise_future_order_dict: any;
    is_order_data_changeable: any = false;
    show_order_table = true;
    future_order_alert_content = '';
    milk_litre_limit = 0;
    max_milk_litre_limit = 0;
    milk_product_list_for_order = [];
    is_min_milk_litre_limit_reached = true;
    is_max_milk_litre_limit_reached = false;
    current_total_milk_litre = 0
    message: string = null;
    extra_amount: any;
    allowance_config: any;
    allowance_extra_quantity: number;
    extra_milk: number = 0;
    mandatory_banner: boolean = false;
    walletAmount: any
    mandatory: boolean = false;

    constructor(private httpservice: HttpService, public dialog: MatDialog, private route: Router) {
        this.getCurrentDate();
        this.InfoData();
    }


    addToWallet() {
        const confirmAdd = confirm(`Do you want to add ${this.walletAmount} to your wallet?`);

        if (confirmAdd) {
            alert(`You have added ${this.walletAmount} to your wallet!`);
            // Here, you can add additional logic like sending the amount to a backend service.
        }
    }
    async getCurrentDate() {
        let date: any = await this.httpservice.getCurrentDate();
        this.today_date_time = new Date((date));
        setInterval(() => {
            this.today_date_time.setSeconds(this.today_date_time.getSeconds() + 1);
        }, 1000);
        let today = new Date((date));
        today.setDate(today.getDate() + 1);
        this.tomorrow = new Date(today).toISOString().split('T')[0];
        let today_for_commission = new Date((date));
        today_for_commission.setDate(1);
        let data_dict = {
            'month_in_string': this.monthNames[today_for_commission.getMonth()],
            'month_in_integer': today_for_commission.getMonth(),
            'year': today_for_commission.getFullYear()
        }
        this.month_list_for_last_12_months.push(data_dict);
        for (let i = 0; i <= 11; i++) {
            today_for_commission.setMonth(today_for_commission.getMonth() - 1);
            let data_dict = {
                'month_in_string': this.monthNames[today_for_commission.getMonth()],
                'month_in_integer': today_for_commission.getMonth(),
                'year': today_for_commission.getFullYear()
            };
            this.month_list_for_last_12_months.push(data_dict);

        }
        this.serveLastDate();
    }

    serveLastDate() {
        const request_data = {
            'from': 'website',
        };
        this.httpservice.getLastDateForAgentOrder(request_data).subscribe((data) => {
            this.booth_details = data;
            this.is_business_selected = true;
            this.selected_index = 0;
            this.final_price = 0;
            this.is_date_editable = false;
            this.selected_business_code = data['booth_code']
            // this.service.set_booth_data = this.selected_business_code;
            // this.service.getHistory.emit(true);
            this.serveProductList();
        }, (error) => {
            this.is_business_selected = false;
            this.selected_business_code = null;
            console.error(error);
            alert(error['error']['error_message']);
        });
    }

    // Booth Agent Due Amount

    InfoData() {
        this.httpservice.getBoothAgentWalletAmount().subscribe((data) => {
            this.message = data['message']
            this.extra_amount = data['amount']
        });
    }

    async serveProductList() {
        this.show_order_table = true;
        this.product_form_dict = {};
        let data_dict = {
            'from': 'website',
        };
        this.httpservice.getProductList(data_dict).subscribe(async (data) => {
            this.product_list = data['product_list'];
            this.session_list = data['session_list'];
            this.product_wise_minumum = {};
            data['product_list'].forEach(product_element => {
                this.product_wise_minumum[product_element.product_id] = {
                    'is_reached': false,
                    'value_in_litre': 0
                };
            });
            data['product_list'].filter(x => x.product_group_id == 1).forEach(product_element => {
                if (this.booth_details.hasOwnProperty('last_date_product_data')) {
                    let prodid = product_element.product_id;
                    let tot1;
                    let tot2;
                    var sess1 = this.booth_details.last_date_product_data.product[1];
                    var sess2 = this.booth_details.last_date_product_data.product[2];
                    if (sess1 != undefined)
                        tot1 = this.booth_details.last_date_product_data.product[1][prodid];
                    if (sess2 != undefined)
                        tot2 = this.booth_details.last_date_product_data.product[2][prodid];
                    tot1 = tot1 == undefined ? 0 : tot1.quantity;
                    tot2 = tot2 == undefined ? 0 : tot2.quantity;
                    let totqty = tot1 + tot2;
                    this.business_wise_maximum.push({
                        'productid': product_element.product_id,
                        'short_name': product_element.product_short_name,
                        'is_reached': product_element.business_wise_product_max_quantity !== null && totqty > product_element.business_wise_product_max_quantity,
                        'value_in_litre': totqty == undefined ? 0 : totqty,
                        'business_wise_max_lmt': product_element.business_wise_product_max_quantity
                    });
                }
            });
            let curd_index = this.product_list.map((element) => {
                return element.product_id;
            }).indexOf(25);
            this.curd_product_index = curd_index;
            this.product_availability = data['product_availability'];
            this.product_groupby_list = data['product_groupby_list'];
            this.product_quantity_variation_list = data['product_quantity_variation_list'];
            this.minimum_quantity_for_product = data['minimum_quantity_for_product'];
            this.overall_litre_limit = data['overall_litre_limit'];
            this.product_wise_minimum_quantity = data['product_wise_minimum_quantity'];
            this.business_wise_future_order_dict = data['business_wise_future_order_dict'];
            this.future_order_alert_content = data['future_order_alert_content'];
            let last_date_date = this.booth_details;
            this.customer_wallet = last_date_date['wallet_balance'];
            this.final_customer_wallet = last_date_date['wallet_balance'];
            this.business_type_id = last_date_date['business_type_id'];
            this.milk_litre_limit = data['milk_litre_limit'];
            this.max_milk_litre_limit = data['max_milk_litre_limit']
            this.milk_product_list_for_order = data['milk_product_list'];
            this.allowance_config = data['allowance_config'];
            if (last_date_date['is_exists']) {
                if (last_date_date['next_date_from_last_date'] < this.tomorrow) {
                    let today = this.today_date_time;
                    let current_time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                    if (Date.parse('01/01/2011 ' + current_time) < Date.parse('01/01/2011 ' + this.session_list[1]['expiry_time'])) {
                        this.form_start_date = this.today_date_time.toISOString().split('T')[0];
                    } else {
                        this.form_start_date = this.tomorrow;
                    }
                } else {
                    this.form_start_date = last_date_date['next_date_from_last_date'];
                }
                await this.getRouteIndentCloseStatus();

                let last_date_product_data = last_date_date['last_date_product_data'];
                this.product_form_dict[this.form_start_date] = {};
                this.product_form_dict[this.form_start_date]['product'] = {};
                this.product_form_dict[this.form_start_date]['total_price_per_product'] = {};
                this.product_form_dict[this.form_start_date]['total_price_per_session'] = {};
                this.product_form_dict[this.form_start_date]['total_price_per_date'] = last_date_product_data['total_price_per_date']
                data['session_list'].forEach(session_element => {
                    this.product_form_dict[this.form_start_date]['product'][session_element.id] = {};
                    if (last_date_product_data['product'].hasOwnProperty(session_element.id)) {
                        if (last_date_product_data['total_price_per_session'].hasOwnProperty(session_element.id)) {
                            this.product_form_dict[this.form_start_date]['total_price_per_session'][session_element.id] = last_date_product_data['total_price_per_session'][session_element.id];
                        } else {
                            this.product_form_dict[this.form_start_date]['total_price_per_session'][session_element.id] = 0;
                        }
                        data['product_list'].forEach(product_element => {
                            if (!this.checkExpiry(this.form_start_date, session_element, product_element)) {
                                this.is_session_wise_edit[session_element.id] = true;
                                if (last_date_product_data['product'][session_element.id].hasOwnProperty(product_element.product_id)) {
                                    this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id] = {};
                                    this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id]['quantity'] = last_date_product_data['product'][session_element.id][product_element.product_id]['quantity'];
                                    this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id]['price'] = last_date_product_data['product'][session_element.id][product_element.product_id]['quantity'] * product_element['product_mrp'];
                                } else {
                                    this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id] = {
                                        'quantity': null,
                                        'price': 0
                                    };
                                }
                            } else {
                                this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id] = {
                                    'quantity': null,
                                    'price': 0
                                };
                            }
                        });
                    } else {
                        this.product_form_dict[this.form_start_date]['total_price_per_session'][session_element.id] = 0;
                        data['product_list'].forEach(product_element => {
                            this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id] = {
                                'quantity': null,
                                'price': 0
                            };
                        });
                    }
                });
                this.product_form_dict[this.form_start_date]['total_price_per_date'] = 0;
                data['product_list'].forEach(product_element => {
                    let total_cost = 0;
                    data['session_list'].forEach(session_element => {
                        total_cost += this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id]['price'];
                    });
                    this.product_form_dict[this.form_start_date]['total_price_per_product'][product_element.product_id] = total_cost;
                    this.product_form_dict[this.form_start_date]['total_price_per_date'] += this.product_form_dict[this.form_start_date]['total_price_per_product'][product_element.product_id];
                });
                console.log('form date', this.form_start_date)
                console.log(this.product_form_dict)
                this.final_price = this.product_form_dict[this.form_start_date]['total_price_per_date'];
                this.final_price_before_wallet = this.product_form_dict[this.form_start_date]['total_price_per_date'];
                if (this.final_price <= 0) {
                    this.final_price = 0;
                    this.final_price_before_wallet = 0;
                }
                this.order_date_list = Object.keys(this.product_form_dict);
                this.duplicate_order_date_list = Object.keys(this.product_form_dict);
            } else {
                let today = this.today_date_time;
                let current_time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                if (Date.parse('01/01/2011 ' + current_time) < Date.parse('01/01/2011 ' + this.session_list[1]['expiry_time'])) {
                    this.form_start_date = this.today_date_time.toISOString().split('T')[0];
                } else {
                    this.form_start_date = this.tomorrow;
                }
                await this.getRouteIndentCloseStatus();
                this.product_form_dict[this.form_start_date] = {};
                this.product_form_dict[this.form_start_date]['product'] = {};
                this.product_form_dict[this.form_start_date]['total_price_per_product'] = {};
                this.product_form_dict[this.form_start_date]['total_price_per_session'] = {};
                this.product_form_dict[this.form_start_date]['total_price_per_date'] = 0;
                data['session_list'].forEach(session_element => {
                    this.product_form_dict[this.form_start_date]['product'][session_element.id] = {};
                    this.product_form_dict[this.form_start_date]['total_price_per_session'][session_element.id] = 0;
                    data['product_list'].forEach(product_element => {
                        this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id] = {
                            'quantity': null,
                            'price': 0
                        };
                        this.product_form_dict[this.form_start_date]['total_price_per_product'][product_element.product_id] = 0;
                    });
                });
                this.order_date_list = Object.keys(this.product_form_dict);
                this.duplicate_order_date_list = Object.keys(this.product_form_dict);
            }
            if (this.business_wise_future_order_dict[this.business_type_id]['is_future_order_accept']) {
                this.is_order_data_changeable = true;
                this.future_order_alert_content = this.future_order_alert_content + ' பதியவேண்டுமா?';
            } else {
                this.future_order_alert_content = this.future_order_alert_content + ' செய்ய இயலாது';
            }
            if (this.form_start_date > this.tomorrow) {
                if (confirm(this.future_order_alert_content)) {
                    if (!this.business_wise_future_order_dict[this.business_type_id]['is_future_order_accept']) {
                        this.show_order_table = false;
                    }
                } else {
                    this.route.navigateByUrl('');
                    return false;
                }
            }
            if (this.minimum_quantity_for_product.hasOwnProperty(this.business_type_id)) {
                data['product_list'].forEach(product_element => {
                    let total_litre = 0;
                    data['session_list'].forEach(session_element => {
                        total_litre += (this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id]['quantity'] * product_element.product_quantity) / 1000;
                    });
                    this.product_wise_minumum[product_element.product_id]['value_in_litre'] = total_litre;
                    let minimum_quantity = this.minimum_quantity_for_product[this.business_type_id][product_element.product_id];
                    if (total_litre < minimum_quantity) {
                        this.product_wise_minumum[product_element.product_id]['is_reached'] = false;
                    } else {
                        this.product_wise_minumum[product_element.product_id]['is_reached'] = true;
                    }
                });
                this.checkMinimumIsReached();
            }
            let total_milk_litre = 0
            this.milk_product_list_for_order.forEach(element => {
                this.session_list.forEach(session_element => {
                    if (this.product_form_dict[this.form_start_date]['product'][session_element.id].hasOwnProperty(element.id)) {
                        total_milk_litre += (this.product_form_dict[this.form_start_date]['product'][session_element.id][element.id]['quantity'] * element.quantity) / 1000;
                    }
                });
            });
            this.current_total_milk_litre = total_milk_litre;
            this.is_min_milk_litre_limit_reached = true;
            let group_total_litre = 0
            if (this.allowance_config['status']) {
                group_total_litre = this.calculateGroupTotalLitre(this.allowance_config['product_group_id'], this.form_start_date);
                this.checkAllowance(group_total_litre);
            }

            if (total_milk_litre > 0) {
                if (total_milk_litre >= this.milk_litre_limit) {
                    this.is_min_milk_litre_limit_reached = true;
                } else {
                    this.is_min_milk_litre_limit_reached = false;
                }
                if (this.allowance_config['status']) {
                    let is_mandatory_check = this.isAllowanceMandatoryLimit(group_total_litre);
                    if (this.max_milk_litre_limit != null) {
                        if (total_milk_litre > (this.max_milk_litre_limit + this.extra_milk) || is_mandatory_check) {
                            this.is_max_milk_litre_limit_reached = true
                        }
                        else {
                            this.is_max_milk_litre_limit_reached = false
                        }
                    }
                }
            }
            if (this.overall_litre_limit.hasOwnProperty(this.business_type_id)) {
                let total_litre = 0;
                data['product_list'].forEach(product_element => {
                    data['session_list'].forEach(session_element => {
                        total_litre += (this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id]['quantity'] * product_element.product_quantity) / 1000;
                    });
                });
                let minimum_quantity = this.overall_litre_limit[this.business_type_id];
                if (total_litre < minimum_quantity) {
                    this.is_overall_limit_reached = false;
                } else {
                    this.is_overall_limit_reached = true;

                }
            }
            setTimeout(() => document.getElementById('1_0').focus(), 500);
            this.serveOrderForFutureWeeks();
        }, (error) => {
            console.error(error);
        });
    }

    checkMinimumIsReached() {
        this.is_minimum_product_is_available = false;
        this.product_list.forEach(product_element => {
            if (this.product_wise_minumum[product_element.product_id]['value_in_litre'] !== 0) {
                if (!this.product_wise_minumum[product_element.product_id]['is_reached']) {
                    this.is_minimum_product_is_available = true;
                }
            }
        });
    }

    getRouteIndentCloseStatus() {
        let data_dict = {
            'selected_date': this.form_start_date,
        };
        return this.httpservice.getRouteIndentStatusPerSessionForWebsite(data_dict).toPromise().then((data) => {
            this.route_indent_prepate_status_per_session[1] = data[1];
            this.route_indent_prepate_status_per_session[2] = data[2];
        }, (error) => {
            console.error(error);
        });
    }

    serveOrderForFutureWeeks() {
        let data_dict = {
            'from': 'website',
        };
        this.httpservice.serveBoothOrderHistoryForFuture(data_dict).subscribe((data) => {
            this.future_order_date_list = Object.keys(data);
            this.one_week_order_details = {};
            this.future_order_date_list.forEach((date_element, date_index) => {
                this.one_week_order_details[date_element] = {};
                this.one_week_order_details[date_element]['session'] = {};
                this.one_week_order_details[date_element]['per_date'] = data[date_element]['per_date'];
                this.one_week_order_details[date_element]['counter_name'] = data[date_element]['counter_name'];
                this.one_week_order_details[date_element]['per_product'] = {};
                this.session_list.forEach(session_element => {
                    this.one_week_order_details[date_element]['session'][session_element.id] = {};
                    this.one_week_order_details[date_element]['session'][session_element.id]['product'] = {};
                    this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time'] = {};
                    this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['time_created'] = null;
                    this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['time_modified'] = null;
                    this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['ordered_by'] = null;
                    this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['modified_by'] = null;
                    if (data[date_element]['session'].hasOwnProperty(session_element.id)) {
                        this.product_list.forEach(product_element => {
                            if (data[date_element]['session'][session_element.id]['product'].hasOwnProperty(product_element.product_id)) {
                                this.one_week_order_details[date_element]['session'][session_element.id]['product'][product_element.product_id] = data[date_element]['session'][session_element.id]['product'][product_element.product_id];
                            } else {
                                this.one_week_order_details[date_element]['session'][session_element.id]['product'][product_element.product_id] = 0;
                            }
                        });
                        this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['time_created'] = data[date_element]['session'][session_element.id]['order_date_and_time']['time_created'];
                        this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['ordered_by'] = data[date_element]['session'][session_element.id]['order_date_and_time']['ordered_by'];
                        if (new Date(data[date_element]['session'][session_element.id]['order_date_and_time']['time_created']).getTime() !== new Date(data[date_element]['session'][session_element.id]['order_date_and_time']['time_modified']).getTime()) {
                            this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['time_modified'] = data[date_element]['session'][session_element.id]['order_date_and_time']['time_modified'];
                            this.one_week_order_details[date_element]['session'][session_element.id]['order_date_and_time']['modified_by'] = data[date_element]['session'][session_element.id]['order_date_and_time']['modified_by'];
                        }
                    } else {
                        this.product_list.forEach(product_element => {
                            this.one_week_order_details[date_element]['session'][session_element.id]['product'][product_element.product_id] = 0;
                        });
                    }
                });
                this.product_list.forEach(product_element => {
                    if (data[date_element]['per_product'].hasOwnProperty(product_element.product_id)) {
                        this.one_week_order_details[date_element]['per_product'][product_element.product_id] = data[date_element]['per_product'][product_element.product_id];
                    } else {
                        this.one_week_order_details[date_element]['per_product'][product_element.product_id] = 0;
                    }
                });
            });
        }, (error) => {
            console.error(error);
        });
    }

    async addNewFormClicked() {
        this.display_confirmed_bill = false;
        let last_index = this.order_date_list.length - 1;
        let last_index_date: any = this.order_date_list[last_index];
        let last_index_date_in_format: any = new Date(last_index_date);
        let tomorrow: any = new Date(last_index_date_in_format.setDate(last_index_date_in_format.getDate() + 1));
        tomorrow = tomorrow.toISOString().split('T')[0];
        if (this.order_date_list.length === 1) {
            let data_dict = {
                'date': tomorrow,
                'from': 'website',
                'business_code': this.selected_business_code
            };
            this.httpservice.checkOrderExists(data_dict).subscribe((data) => {
                if (data['is_exists']) {
                    tomorrow = data['last_date']
                    this.order_date_list.push(tomorrow);
                    this.duplicate_order_date_list.push(tomorrow);
                    this.product_form_dict[tomorrow] = JSON.parse(JSON.stringify(this.product_form_dict[last_index_date]));
                    let after_new_date_last_index = this.order_date_list.length - 1;
                    this.selected_index = after_new_date_last_index;
                } else {
                    this.order_date_list.push(tomorrow);
                    this.duplicate_order_date_list.push(tomorrow);
                    this.product_form_dict[tomorrow] = JSON.parse(JSON.stringify(this.product_form_dict[last_index_date]));
                    let after_new_date_last_index = this.order_date_list.length - 1;
                    this.selected_index = after_new_date_last_index;
                }
            }, (error) => {
                console.error(error);
            });
        } else {
            this.order_date_list.push(tomorrow);
            this.duplicate_order_date_list.push(tomorrow);
            this.product_form_dict[tomorrow] = JSON.parse(JSON.stringify(this.product_form_dict[last_index_date]));
            let after_new_date_last_index = this.order_date_list.length - 1;
            this.selected_index = after_new_date_last_index;
        }
        this.order_date_list.forEach(element => {
            this.final_price += this.product_form_dict[element]['total_price_per_date'];
            // this.final_price_before_wallet += this.product_form_dict[element]['total_price_per_date'];
        });
    }

    removeFormClicked() {
        this.display_confirmed_bill = false;
        let last_index = this.order_date_list.length - 1;
        let last_index_date: any = this.order_date_list[last_index];
        delete this.product_form_dict[last_index_date];
        this.order_date_list.pop();
        this.duplicate_order_date_list.pop();
        this.final_price = 0;
        this.final_price_before_wallet = 0;
        this.order_date_list.forEach(element => {
            this.final_price += this.product_form_dict[element]['total_price_per_date'];
            // this.final_price_before_wallet += this.product_form_dict[element]['total_price_per_date'];
        });
        let after_new_date_last_index = this.order_date_list.length - 1;
        this.selected_index = after_new_date_last_index;
    }



    async confirmCliked() {
        if (this.business_wise_maximum.length > 0) {
            let max_litre = this.business_wise_maximum.filter(x => x.is_reached == true);
            if (max_litre.length > 0) {
                let error_message = '';
                for (let msg of max_litre) {
                    error_message += 'Maximum limit has been reached for ' + msg.short_name + '\n';
                }
                alert(error_message);
                return false;
            }
        }

        if (this.mandatory) {
            let error_message = 'Mandatory Curd ' + this.allowance_config['product_base_limit'] + 'L for Order above ' + this.allowance_config['mandatory_limit'];
            alert(error_message)
            return false
        }

        document.getElementById('confirm_button').blur();
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
        const dialogRef = this.dialog.open(AgentConfirmOrderComponent, {
            height: '600px',
            width: '600px',
            data: {
                'order_details': this.product_form_dict,
                'wallet_balance': this.customer_wallet,
                'business_type_id': this.business_type_id,
                'final_price': this.final_price,
                'order_dates': this.order_date_list,
                'business_code': this.selected_business_code,
                'is_session_wise_edit': this.is_session_wise_edit,
                'extra_amount': this.extra_amount,
                'extra_milk_qty': this.extra_milk == null ? 0 : this.extra_milk,
                'extra_allowance_qty': this.allowance_extra_quantity == null ? 0 : this.allowance_extra_quantity
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            const res = result;
            if (res['is_order_placed']) {
                alert('Your order saved..');
                this.serveLastDate();
                this.is_session_wise_edit = { 1: false, 2: false };
            }
            if (res['is_indent_closed']) {
                this.serveLastDate();
                this.is_session_wise_edit = { 1: false, 2: false };

            }
        });

    }
    passwordChangeCalled() {
        const dialogRef = this.dialog.open(PasswordChangeOptionComponent, {
            height: '500px',
            width: '600px',
            data: {},
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            const res = result;
        });
    }

    editClicked(selected_date) {
        const dialogRef = this.dialog.open(AgentOrderEditComponent, {
            height: '600px',
            width: '600px',
            data: {
                'selected_date': selected_date,
                'selected_business_code': this.selected_business_code,
                'business_type_id': this.business_type_id
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            const res = result;
            if (res) {
                this.serveOrderForFutureWeeks();
                this.getCurrentDate();
            }
        });
    }

    formExpandClicked(index) {
        if (this.selected_index === index) {
            this.selected_index = null;
        } else {
            this.selected_index = index;
        }
    }

    changeToEditableDate() {
        this.is_date_editable = true;
    }

    openLooseMilkCard() {
        this.enable_loose_milk_card = !this.enable_loose_milk_card;
    }

    // moving cursour to next input box if its disabled recall function itself
    enterKeyCalled(session_id, product_index, date) {
        if (session_id === 2 && product_index === this.curd_product_index) {
            document.getElementById('confirm_button').focus();
        } else if (session_id === 2 && product_index + 1 === this.product_list.length) {
            document.getElementById('confirm_button').focus();
        } else {
            if (session_id === 1) {
                session_id = 2;
            } else {
                session_id = 1;
                product_index = product_index + 1;
            }
            let session_obj = this.session_list.filter((element) => {
                return element.id === session_id;
            });
            if (this.checkExpiry(date, session_obj[0], this.product_list[product_index])) {
                this.enterKeyCalled(session_id, product_index, date);
            } else {
                let next_element_id = session_id + '_' + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    leftArrowKeyCalled(session_id, product_index, date) {
        if (session_id === 1 && product_index === 0) {
        } else {
            if (session_id === 1) {
                session_id = 2;
                product_index -= 1;
            } else {
                session_id = 1;
            }
            let session_obj = this.session_list.filter((element) => {
                return element.id === session_id;
            });
            if (this.checkExpiry(date, session_obj[0], this.product_list[product_index])) {
                this.leftArrowKeyCalled(session_id, product_index, date);
            } else {
                let next_element_id = session_id + '_' + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    upArrowKeyCalled(session_id, product_index, date) {
        if (product_index === 0) {
        } else {
            product_index -= 1;
            let session_obj = this.session_list.filter((element) => {
                return element.id === session_id;
            });
            if (this.checkExpiry(date, session_obj[0], this.product_list[product_index])) {
                this.upArrowKeyCalled(session_id, product_index, date);
            } else {
                let next_element_id = session_id + '_' + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    rightArrowKeyCalled(session_id, product_index, date) {
        if (session_id === 2 && product_index === this.curd_product_index) {
            document.getElementById('confirm_button').focus();
        } else if (session_id === 2 && product_index + 1 === this.product_list.length) {
            document.getElementById('confirm_button').focus();
        } else {
            if (session_id === 1) {
                session_id = 2;
            } else {
                session_id = 1;
                product_index = product_index + 1;
            }
            let session_obj = this.session_list.filter((element) => {
                return element.id === session_id;
            });
            if (this.checkExpiry(date, session_obj[0], this.product_list[product_index])) {
                this.rightArrowKeyCalled(session_id, product_index, date);
            } else {
                let next_element_id = session_id + '_' + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    downArrowKeyCalled(session_id, product_index, date) {
        if (session_id === 2 && product_index === this.curd_product_index) {
            document.getElementById('confirm_button').focus();
        } else if (product_index + 1 === this.product_list.length) {
            document.getElementById('confirm_button').focus();
        } else {
            product_index += 1;
            let session_obj = this.session_list.filter((element) => {
                return element.id === session_id;
            });
            if (this.checkExpiry(date, session_obj[0], this.product_list[product_index])) {
                this.downArrowKeyCalled(session_id, product_index, date);
            } else {
                let next_element_id = session_id + '_' + product_index;
                if (document.getElementById(next_element_id) !== null) {
                    document.getElementById(next_element_id).focus();
                }
            }
        }
    }

    eventEmitOnInput(session_id, product_index, date, event) {
        let key_code = event.keyCode;
        if (key_code === 13) {
            this.enterKeyCalled(session_id, product_index, date);
        } else if (key_code === 37) {
            this.leftArrowKeyCalled(session_id, product_index, date);
        } else if (key_code === 38) {
            this.upArrowKeyCalled(session_id, product_index, date);
        } else if (key_code === 39) {
            this.rightArrowKeyCalled(session_id, product_index, date);
        } else if (key_code === 40) {
            this.downArrowKeyCalled(session_id, product_index, date);
        }
    }

    checkLooseMilkQuantityWarning(date, product_index) {
        this.enable_minimum_warning_for_loose_milk = false;
        this.product_groupby_list[3].forEach((loose_milk_id) => {
            this.session_list.forEach(session_element => {
                let value = this.product_form_dict[date]['product'][session_element.id][loose_milk_id]['quantity'];
                if (value !== null && value !== 0) {
                    if (value < this.product_list[product_index]['minimum_quantity']) {
                        this.enable_minimum_warning_for_loose_milk = true;
                    }
                }
            });
        });
    }

    productQuantityChanged(date, session_id, product_id, product_price, product_index, product_quantity) {
        const product_group_id = this.product_list[product_index]['product_group_id'];
        let temp_quantity = this.product_form_dict[date]['product'][session_id][product_id]['quantity'];
        if (temp_quantity !== '') {
            this.is_session_wise_edit[session_id] = true;
            this.product_form_dict[date]['product'][session_id][product_id]['quantity'] = parseInt(this.product_form_dict[date]['product'][session_id][product_id]['quantity']);
        } else {
            this.product_form_dict[date]['product'][session_id][product_id]['quantity'] = null;
        }
        let quantity = this.product_form_dict[date]['product'][session_id][product_id]['quantity'];
        if (product_group_id === 3) {
            this.checkLooseMilkQuantityWarning(date, product_index);
            this.product_form_dict[date]['product'][session_id][product_id]['price'] = 0;
            this.product_quantity_variation_list[product_id].forEach(element => {
                if (quantity >= element['min_quantity'] && quantity <= element['max_quantity']) {
                    this.product_form_dict[date]['product'][session_id][product_id]['price'] = quantity * element['mrp'];
                }
            });
        } else {
            this.product_form_dict[date]['product'][session_id][product_id]['price'] = quantity * product_price;
        }
        // add the product session based total price
        let total_cost = 0;
        let total_litre = 0;

        this.session_list.forEach(session_element => {
            total_cost += this.product_form_dict[date]['product'][session_element.id][product_id]['price'];
            total_litre += (this.product_form_dict[date]['product'][session_element.id][product_id]['quantity'] * product_quantity) / 1000;
        });
        this.product_form_dict[date]['total_price_per_product'][product_id] = total_cost;
        this.product_wise_minumum[product_id]['value_in_litre'] = total_litre;

        if (this.product_wise_minimum_quantity.hasOwnProperty(product_id)) {
            this.checkCupCurdIsValid(product_id, date)
        }
        if (this.minimum_quantity_for_product.hasOwnProperty(this.business_type_id)) {
            let minimum_quantity = this.minimum_quantity_for_product[this.business_type_id][product_id];
            if (total_litre < minimum_quantity) {
                this.product_wise_minumum[product_id]['is_reached'] = false;
            } else {
                this.product_wise_minumum[product_id]['is_reached'] = true;
            }
            this.checkMinimumIsReached();
        }

        if (product_group_id === 1) {
            var temp_session = 0;
            if (session_id == 1)
                temp_session = session_id + 1;
            else
                temp_session = session_id - 1;
            let totquantity = this.product_form_dict[date]['product'][temp_session][product_id]['quantity'] + quantity;

            if (this.business_wise_maximum.length > 0) {
                if (totquantity > this.product_list[product_index]['business_wise_product_max_quantity'] && !(this.product_list[product_index]['business_wise_product_max_quantity'] == null)) {
                    this.business_wise_maximum[product_index]['is_reached'] = true;
                    this.business_wise_maximum[product_index]['value_in_litre'] = totquantity;
                }
                else {
                    this.business_wise_maximum[product_index]['is_reached'] = false;
                    this.business_wise_maximum[product_index]['value_in_litre'] = totquantity;
                }
            }
        }

        if (this.overall_litre_limit.hasOwnProperty(this.business_type_id)) {
            let total_litre = 0;
            this.product_list.forEach(product_element => {
                this.session_list.forEach(session_element => {
                    total_litre += (this.product_form_dict[this.form_start_date]['product'][session_element.id][product_element.product_id]['quantity'] * product_element.product_quantity) / 1000;
                });
            });
            let minimum_quantity = this.overall_litre_limit[this.business_type_id];
            if (total_litre < minimum_quantity) {
                this.is_overall_limit_reached = false;
            } else {
                this.is_overall_limit_reached = true;

            }
        }
        // add the total product price date wise
        this.product_form_dict[date]['total_price_per_date'] = 0;
        this.product_list.forEach(product_element => {
            this.product_form_dict[date]['total_price_per_date'] += this.product_form_dict[date]['total_price_per_product'][product_element.product_id];
        });
        // add total price per session
        let total_cost_per_session = 0;
        this.product_form_dict[date]['total_price_per_session'][session_id] = 0;
        this.product_list.forEach(product_element => {
            total_cost_per_session += this.product_form_dict[date]['product'][session_id][product_element.product_id]['price'];
        });
        this.product_form_dict[date]['total_price_per_session'][session_id] = total_cost_per_session;
        // add final total price
        this.final_price = 0;
        // this.final_price_before_wallet = 0;
        this.order_date_list.forEach(element => {
            this.final_price += this.product_form_dict[element]['total_price_per_date'];
            this.final_price_before_wallet += this.product_form_dict[element]['total_price_per_date'];
        });

        let total_milk_litre = 0
        this.milk_product_list_for_order.forEach(element => {
            this.session_list.forEach(session_element => {
                if (this.product_form_dict[this.form_start_date]['product'][session_element.id].hasOwnProperty(element.id)) {
                    total_milk_litre += (this.product_form_dict[this.form_start_date]['product'][session_element.id][element.id]['quantity'] * element.quantity) / 1000;
                }
            });
        });
        this.current_total_milk_litre = total_milk_litre;

        let group_total_litre = 0
        if (this.allowance_config['status']) {
            group_total_litre = this.calculateGroupTotalLitre(this.allowance_config['product_group_id'], date);
            this.checkAllowance(group_total_litre)
        }

        if (total_milk_litre > 0) {
            if (total_milk_litre >= this.milk_litre_limit) {
                this.is_min_milk_litre_limit_reached = true;
            } else {
                this.is_min_milk_litre_limit_reached = false;
            }
            if (this.allowance_config['status']) {
                let is_mandatory_check = this.isAllowanceMandatoryLimit(group_total_litre);
                if (this.max_milk_litre_limit != null) {
                    if (total_milk_litre > (this.max_milk_litre_limit + this.extra_milk) || is_mandatory_check) {
                        this.is_max_milk_litre_limit_reached = true
                    }
                    else {
                        this.is_max_milk_litre_limit_reached = false
                    }
                }
            }
        }
    }

    isAllowanceMandatoryLimit(group_total_litre) {
        if (!this.allowance_config['status']) {
            return false
        }
        if (this.allowance_config['mandatory_limit'] == 0) {
            return false
        }
        if (this.current_total_milk_litre >= this.allowance_config['mandatory_limit']) {
            this.mandatory = true;
            if (group_total_litre < this.allowance_config['product_base_limit']) {
                return true
            }
            else {
                this.mandatory = false;
                return false
            }
        }
        else {
            this.mandatory = false;
            return false
        }
    }

    checkAllowance(group_total_litre) {
        if (this.allowance_config['status']) {
            this.allowance_extra_quantity = group_total_litre - this.allowance_config['product_base_limit'];
            if (this.allowance_extra_quantity >= this.allowance_config['allowance_min_quantity']) {
                let base_limit_count = Math.trunc(this.allowance_extra_quantity / this.allowance_config['allowance_min_quantity']);
                this.extra_milk = (base_limit_count * this.allowance_config['allowance_min_quantity']) * this.allowance_config['allowance_multiplier'];
            }
            else {
                this.extra_milk = null;
                this.allowance_extra_quantity = 0;
            }
        }
    }

    calculateGroupTotalLitre(product_group_id, date) {
        let group_total_litre = 0
        let product_groupby_list_length = this.product_groupby_list[product_group_id].length
        if (product_groupby_list_length == 0) {
            return 0
        }
        this.product_groupby_list[product_group_id].forEach(prod_id => {
            this.session_list.forEach(session_element => {
                let product_quantity = this.product_list.find(x => x.product_id == prod_id).product_quantity
                let prod_litre = (this.product_form_dict[date]['product'][session_element.id][prod_id]['quantity'] * product_quantity) / 1000;
                group_total_litre += prod_litre
            });
        });

        return group_total_litre
    }

    checkCupCurdIsValid(product_id, date) {
        this.is_product_wise_minimum_check_is_valid = true;
        let minimum_product_list = Object.keys(this.product_wise_minimum_quantity);
        minimum_product_list.forEach(product_element => {
            this.session_list.forEach(element => {
                let quantity = this.product_form_dict[date]['product'][element.id][product_element]['quantity'];
                let minimum_quantity = this.product_wise_minimum_quantity[product_element]['value_in_quantity'];
                if (quantity % minimum_quantity != 0) {
                    this.is_product_wise_minimum_check_is_valid = false;
                }
            });
        });
    }

    checkConfirmButtonValid() {
        if (this.final_price == 0 || this.enable_minimum_warning_for_loose_milk || this.is_minimum_product_is_available || !this.is_overall_limit_reached || !this.is_product_wise_minimum_check_is_valid || !this.is_min_milk_litre_limit_reached || this.is_max_milk_litre_limit_reached) {
            return true;
        } else {
            return false;
        }
    }


    minValue(index) {
        if (this.order_date_list.length === 1) {
            return this.tomorrow;
        } else {
            return this.order_date_list[index];
        }
    }

    checkExpiry(order_date, session, product) {
        let order_date_in_format = new Date(order_date);
        let expiry_date: any = new Date(order_date_in_format.setDate(order_date_in_format.getDate() - session.expiry_day_before));
        expiry_date = expiry_date.toISOString().split('T')[0];
        let expiry_date_and_time: any = new Date(expiry_date + ' ' + session.expiry_time);
        if (this.route_indent_prepate_status_per_session[session.id]) {
            return true;
        } else if (this.product_availability[session.id][product.product_id] === false) {
            return true;
        } else if (this.today_date_time > expiry_date_and_time) {
            return true;
        } else {
            return false;
        }
    }

    async dateValueChanged(index, date) {
        this.display_confirmed_bill = false;
        let old_date = this.duplicate_order_date_list[index];
        // let date = this.order_date_list[index];
        this.form_start_date = date;
        await this.getRouteIndentCloseStatus();
        let data_dict = {
            'date': date,
            'from': 'website',
            'business_code': this.selected_business_code
        };
        this.httpservice.checkOrderExists(data_dict).subscribe((data) => {
            if (data['is_exists']) {
                alert('Aleardy you have order in this date');
                this.order_date_list[index] = old_date;
            } else {
                if (this.duplicate_order_date_list.includes(date)) {
                    this.order_date_list[index] = old_date;
                    alert('This date already available');
                } else {
                    delete Object.assign(this.product_form_dict, { [date]: this.product_form_dict[old_date] })[old_date];
                    this.duplicate_order_date_list[index] = date;
                }
            }
        }, (error) => {
            console.error(error);
        });
    }
    ngOnInit() {
    }

    getMonthlyReport() {
        const data_dict = {
            'from': 'website',
            'selected_month': this.month_list_for_last_12_months[this.selected_month]
        };
        this.is_data_available = false;
        this.httpservice.serveAgentMonthlyReport(data_dict).subscribe((data) => {
            this.is_data_available = true;
            if (data['status']) {
                this.milk_product_list = data['milk_product_list'];
                this.fermented_product_list = data['fermented_product_list'];
                this.milk_product_report = data['milk_product_report']['product_details'];
                this.milk_product_total_price = data['milk_product_report']['total_price'];
                this.milk_product_date_list = Object.keys(this.milk_product_report).sort();
                this.fermented_product_report = data['fermented_product_report']['product_details'];
                this.fermented_product_total_price = data['fermented_product_report']['total_price'];
                this.overall_month_report = data['overall_month_report']['product_details'];
                this.overall_month_total_price = data['overall_month_report']['total_price'];
                this.global_config_dict = data['global_config_dict'];
                this.commission_details = data['commission_details'];
                this.fermented_product_date_list = Object.keys(this.fermented_product_report).sort();
            }
        }, (error) => {
            this.is_data_available = true;
            console.error(error);
        });
    }

    checkEnableEditButton(date) {
        let today = new Date(this.today_date_time);
        let current_time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        if (Date.parse(this.today_date_time.toISOString().split('T')[0] + ' ' + current_time) < Date.parse(date + ' ' + this.session_list[1]['expiry_time'])) {
            return true;
        } else {
            return false;
        }
    }

}

import { Injectable } from "@angular/core";
// import { MatSnackBar } from '@angular/material';
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";

@Injectable({
    providedIn: "root",
})
export class GlobalService {
    private _master_page_title: string = null;
    // private _server_url = 'http://localhost:8000';
    private _server_url = "https://server-aws.aavincoimbatore.com"; // Prod
    // private _server_url = "https://develop-server-aws.aavincoimbatore.com"; // Test
    // public _server_url = "https://aavin-dev-temp.aavincoimbatore.com/"; // temp
    // private _server_url = "http://192.168.68.100:8001";

    constructor(public router: Router, private snackBar: MatSnackBar) {}

    get server_url() {
        return this._server_url;
    }

    showSnakeBar(message: string) {
        this.snackBar.open(message, "", {
            duration: 3000,
        });
    }

    navigateTo(component_path) {
        this.router.navigateByUrl(component_path);
    }

    set set_master_page_title(page_title) {
        this._master_page_title = page_title;
    }

    get get_master_page_title() {
        return this._master_page_title;
    }
}

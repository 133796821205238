import { Component, OnInit } from '@angular/core';
// import * as $ from 'jquery';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    counter = 1000;
    constructor() {

    }
    scrollToView(id) {
        document.getElementById(id).scrollIntoView();
    }
    ngOnInit() {
    }
}

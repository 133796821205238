import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/http.service';
import { GlobalService } from 'src/app/global.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { LoginComponent } from '../login/login.component';

@Component({
    selector: 'app-icustomer-password-generation',
    templateUrl: './icustomer-password-generation.component.html',
    styleUrls: ['./icustomer-password-generation.component.scss']
})
export class IcustomerPasswordGenerationComponent implements OnInit {
    entered_customer_code: any = null;
    customer_data: any;
    is_customer_verified = false;
    mobile: any;
    user_id: any;
    entered_mobile_number = null;
    enable_otp = false;
    entered_otp = null;
    is_otp_valid = false;

    constructor(private httpService: HttpService, private global: GlobalService, private dialog: MatDialog) { }

    ngOnInit() {
    }

    proceedCalled() {
        let data_dict = {
            'icustomer_number': null,
            'icustomer_code': this.entered_customer_code
        };
        this.httpService.checkCustomerExist(data_dict).subscribe((data) => {
            this.is_customer_verified = data['found'];
            if (!data['found']) {
                alert('Invalid Customer Code');
                this.entered_customer_code = null;
                return false;
            } else {
                this.customer_data = data;
            }
        }, (error) => {
            console.error(error);
        });
    }

    confirmButtonClicked(mobile_number) {
        this.entered_mobile_number = mobile_number;
        let data_dict = {
            'mobile_number': mobile_number,
            'user_id': this.customer_data['user_id'],
            'for': 'Mobile verfication is '
        };
        this.httpService.sendOtpToMobileNumber(data_dict).subscribe((data) => {
            // this.router.navigateByUrl('/icustomer/order/form');
            this.enable_otp = true;
        }, (error) => {
            console.error(error);
        });
    }

    loginPageClicked() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'modal-class';
        this.dialog.open(LoginComponent, dialogConfig);
    }

    confirmWithOtpClicked() {
        let data_dict = {
            'mobile_number': this.entered_mobile_number,
            'user_id': this.customer_data['user_id'],
            'otp': this.entered_otp,
        };
        this.httpService.confirmOtpCalledForCustomerVerification(data_dict).subscribe((data) => {
            this.global.showSnakeBar('Mobile Number Updated And Password Changed');
            this.is_otp_valid = true;
        }, (error) => {
            alert('Incorrect OTP');
            console.error(error);
        });
    }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpService } from 'src/app/http.service';
import { GlobalService } from 'src/app/global.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomerRegisterValidator } from '../../register/customer-register/customer-register-validators';

@Component({
    selector: 'app-icustomer-aadhar-verification',
    templateUrl: './icustomer-aadhar-verification.component.html',
    styleUrls: ['./icustomer-aadhar-verification.component.scss']
})
export class IcustomerAadharVerificationComponent implements OnInit {
    personal_detail_form: FormGroup;
    constructor(public dialogRef: MatDialogRef<IcustomerAadharVerificationComponent>, private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpService, private global: GlobalService) {
        this.personal_detail_form = this.formBuilder.group({
            address: [null, Validators.compose([Validators.required])],
            aadhar_number: [null, Validators.compose([CustomerRegisterValidator.checkAadharNumber, Validators.required])]
        });
        this.personal_detail_form.get('address').setValue(data['data']['address']);
        this.personal_detail_form.get('aadhar_number').setValue(data['data']['aadhar_number']);

    }

    updateCalled() {
        this.http.updateKycForIcustomer(this.personal_detail_form.value).subscribe((data) => {
            this.dialogRef.close(true);
        }, (error) => {
            console.error(error);
            this.dialogRef.close(false);
        });
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close(false);
    }
}
